import * as base from '../base';
import { APIChannel } from './channels';

export type APIForgotPasswordInput = {
  email: string;
};

export type APIChangePasswordInput = Partial<{
  password1: string;
  password2: string;
}>;

export type APINewPasswordInput = Partial<{
  password1: string;
  password2: string;
  token: string | undefined;
  uid: string | undefined;
}>;

export type APIToken = {
  token: string | undefined;
  uid: string | undefined;
};

export type APIPasswordInput = {
  password: string;
};

export type APIEmailInput = {
  email: string;
};

export type APIUsernameInput = {
  username: string;
};

export type APISignupInput = Partial<{
  username: string;
  email: string;
  password: string;
  type: 'member' | 'organization';
  phoneNumber: string;
  channel: APIChannel['key'];
}>;

export type APILogin = {
  accessToken: string;
  refreshToken: string;
};

export type APILoginInput = Partial<{
  username: string;
  password: string;
  phoneNumber: string;
}>;

export type APIPasswordlessPhoneInput = {
  phoneNumber: string;
  checkExists?: boolean;
  checkAvailable?: boolean;
};

export type APIPasswordlessSignupInput = APIPasswordlessPhoneInput & {
  email: string;
  code: string;
  channel: APIChannel['key'];
};

export type APIPasswordlessLoginInput = APIPasswordlessPhoneInput & {
  code: string;
};

export class AuthAPI extends base.APIBase {
  forgotPassword(data: APIForgotPasswordInput) {
    return super.postEmpty('auth/forgot-password/', { json: data, public: true });
  }

  changePassword(data: APIChangePasswordInput) {
    return super.postEmpty('auth/change-password/', { json: data });
  }

  newPassword(data: APIChangePasswordInput) {
    return super.postEmpty('auth/new-password/', { json: data });
  }

  signup(data: APISignupInput) {
    return super.postEmpty('auth/signup/', { json: data, public: true });
  }

  login(data: APILoginInput) {
    return super._post<APILogin>('auth/login/', { json: data, public: true });
  }

  verify(data: { password: string }) {
    return super._post<APILogin>('auth/verify/', { json: data });
  }

  tokens(data: APIToken) {
    return super.postEmpty('auth/tokens/', { json: data });
  }

  firebaseToken() {
    return super._get<{ token: string }>('auth/firebase-token/');
  }

  password(data: APIPasswordInput) {
    return super.postEmpty('auth/password/', { json: data, public: true });
  }

  checkEmail(data: APIEmailInput) {
    return super.postEmpty('auth/email/', { json: data, public: true });
  }

  checkUsername(data: APIUsernameInput) {
    return super.postEmpty('auth/username/', { json: data, public: true });
  }

  checkPhone(data: APIPasswordlessPhoneInput) {
    return super.postEmpty('auth/phone/', { json: data, public: true });
  }

  sendOTP(data: APIPasswordlessPhoneInput) {
    return super.postEmpty('auth/passwordless/otp/', { json: data, public: true });
  }

  signupPasswordless(data: APIPasswordlessSignupInput) {
    return super.postEmpty('auth/passwordless/signup/', { json: data, public: true });
  }

  loginPasswordless(data: APIPasswordlessLoginInput) {
    return super.postEmpty('auth/passwordless/login/', { json: data, public: true });
  }
}
