import * as base from '../base';

export type APIInternalSkillStamp = {
  id: number;
  name: string;
  description: string;
  image: string;
  latestEndorser?: string;
  endorsementsCount?: number;
  endorsementsCountRange?: number[];
};

export class SkillStampsAPI extends base.ReadOnlyAPIBase<APIInternalSkillStamp> {
  extraOptions = {
    list: { public: true },
  };
  baseUrl = 'skill-stamps/';
}
