import * as base from '../base';

export type APIShippingAddress = {
  id: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  street1: string;
  street2: string | undefined;
  country: string;
  state: string;
  city: string;
  zipcode: string;
};

export type APIShippingAddressInput = {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  street1: string;
  street2: string | undefined;
  country: string;
  state: string;
  city: string;
  zipcode: string;
};

export class ShippingAPI extends base.CrudAPIBase<APIShippingAddress, APIShippingAddressInput> {
  baseUrl = 'shipping_address/';
}
