import React, { createContext, useState } from 'react';

type ImageInfo = {
  url: string;
  width: number;
  height: number;
};

type Images = {
  heroImage1?: ImageInfo;
};

type CareerExplorerSkillsContext = {
  images: Images;
  setImages: (value: Images) => void;
};

export const CareerExplorerSkillsContext = createContext<CareerExplorerSkillsContext>({
  images: {},
  setImages: () => {},
});

type CareerExplorerSkillsProviderProps = React.PropsWithChildren<{}>;
const CareerExplorerSkillsProvider: React.FC<CareerExplorerSkillsProviderProps> = ({
  children,
}) => {
  const [images, setImages] = useState<Images>({});

  return (
    <CareerExplorerSkillsContext.Provider
      value={{
        images,
        setImages,
      }}
    >
      {children}
    </CareerExplorerSkillsContext.Provider>
  );
};

export default CareerExplorerSkillsProvider;
