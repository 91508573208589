import * as base from '../base';
import { APISearchParams } from '../types';

export type APISkill = {
  id: number;
  type:
    | 'Trade Foundation'
    | 'Physical'
    | 'Tools & Equipment"'
    | 'Technical - General'
    | 'Technical - Specialty'
    | 'Health & Safety'
    | 'Employability';
  name: string;
  description: string;
  category: number;
  categoryName?: string;
  trades: string[];
  tradePaths: string[];
  shortDescription: string;
  isActive: boolean;
  deleteDate: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export interface APISkillSearchParams extends APISearchParams {
  id__in?: string;
  category_in?: string;
  trade?: string;
  trade_path?: string;
}

export class SkillsAPI extends base.ReadOnlyAPIBase<APISkill, APISkillSearchParams> {
  baseUrl = 'skills/';
  extraOptions = {
    list: { public: true },
  };
}
