import * as base from '../base';
import { APIList, APISearchParams, Options } from '../types';
import { APILogin, APISignupInput } from './auth';
import { APIIdentity, APIIdentityWithUser } from './identities';
import { APIOrganizationLocation } from './organizationLocations';

export type APIOrganizationProfile = {
  id: number;
  slug: string;
  industry?: number | string | null;
  type:
    | 'Skilled Trades Employer'
    | 'Union'
    | 'Industry Association'
    | 'Education'
    | 'Public Sector';
  isPublic: boolean;
  isUnion: boolean;
  isVisibleMembers: boolean;
  isVerified: boolean;
  name: string;
  intro: string;
  headline: string;
  background: string;
  image: string;
  imageSizes?: {
    big?: string;
    medium?: string;
  };
  employeeDescription: string;
  employeeSize:
    | '1-50'
    | '51-200'
    | '201-500'
    | '501-1000'
    | '1001-5000'
    | '5001-10000'
    | '10001+'
    | null;
  youtubeUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  tiktokUrl: string;
  url: string;
  isActive: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  users?: APIIdentityWithUser[];
  locations: APIOrganizationLocation[];
  isFollowing: boolean;
  isMember: boolean;
  memberType: string;
  channel?: '' | 'NCCER';
};

export type APIOrganizationPrivateProfile = {
  id: number;
  image: string;
  imageSizes?: {
    big?: string;
    medium?: string;
  };
  isFollowing: boolean;
  isMember: boolean;
  slug: string;
  name: string;
  isPublic: boolean;
  isVerified: boolean;
};

export type APIOrganizationProfileInvitePage = {
  id: number;
  image: string;
  imageSizes?: {
    big?: string;
    medium?: string;
  };
  slug: string;
  name: string;
  isPublic: boolean;
  isMember: boolean;
  isAdminOrOwner: boolean;
  isVerified: boolean;
  locations: APIOrganizationLocation[];
  url: string;
  industry?: number | string | null;
  employeeSize:
    | '1-50'
    | '51-200'
    | '201-500'
    | '501-1000'
    | '1001-5000'
    | '5001-10000'
    | '10001+'
    | null;
};

export type APIOrganizationProfileInput = Partial<
  Omit<
    APIOrganizationProfile,
    | 'id'
    | 'slug'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
    | 'isActive'
    | 'imageSizes'
    | 'isFollowing'
    | 'isMember'
  >
> & {
  utmCode?: string;
};

export type APIClaimProfileInput = {
  orgClaimCode: string;
};

export type APIClaimProfileSignupInput = APISignupInput &
  APIClaimProfileInput & {
    firstName: string;
    lastName: string;
  };

export type APIOrganizationUserInput = {
  member: number;
  administrator: number;
  role?: 'organization_admin' | 'organization_owner' | 'organization_user' | 'organization_member';
};

export type APIOrganizationUserInvite = {
  email: string;
  role: 'organization_admin' | 'organization_owner' | 'organization_user' | 'organization_member';
  organization: number;
  fullNameOrEmail?: string;
  uuid?: string;
};

export type APIProcessOrganizationInvite = {
  key: string;
};

export type APIProcessOrganizationInviteResponse = {
  organization: string;
};

export type APIOrganizationUserInviteValidation = {
  profileSlug: string | null;
};

export type APIOrganizationUserInviteInput = Partial<APIOrganizationUserInvite>;

export class OrganizationProfilesAPI extends base.CrudAPIBase<
  APIOrganizationProfile | APIOrganizationPrivateProfile,
  APIOrganizationProfileInput
> {
  baseUrl = 'organizations/profiles/';

  detail(slug: string) {
    return this._get<APIOrganizationProfile | APIOrganizationPrivateProfile>(
      `${this.baseUrl}${slug}/`,
      { public: true }
    );
  }

  update(slug: string, data: APIOrganizationProfileInput) {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'string' || typeof value === 'boolean' || value instanceof Blob) {
        if (value instanceof Blob) {
          formData.append(key, value);
        } else {
          formData.append(key, `${value}`);
        }
      }
    }

    return super._patch<APIOrganizationProfile>(`${this.baseUrl}${slug}/`, { body: formData });
  }

  me(slug: string) {
    return this._get<APIIdentity>(`${this.baseUrl}${slug}/me/`);
  }

  validate(data: APIOrganizationProfileInput) {
    return super._post(`${this.baseUrl}validate/`, {
      json: data,
    });
  }

  validateInstance(slug: string, data: APIOrganizationProfileInput) {
    return super._patch(`${this.baseUrl}${slug}/validate/`, {
      json: data,
    });
  }

  connections(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIOrganizationProfile>>(
      `${this.baseUrl}connections/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  suggestions(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIOrganizationProfile>>(
      `${this.baseUrl}suggested/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  follow(slug: string) {
    return this.postEmpty(`${this.baseUrl}${slug}/follow/`);
  }

  unfollow(slug: string) {
    return this.destroyEmpty(`${this.baseUrl}${slug}/follow/`);
  }

  postClaim(data: APIClaimProfileInput) {
    return this._post<APIOrganizationProfile | APIOrganizationPrivateProfile>(
      `${this.baseUrl}claim/`,
      {
        json: data,
        public: true,
      }
    );
  }

  patchClaim(data: APIClaimProfileSignupInput) {
    return this._patch<APILogin>(`${this.baseUrl}claim/`, {
      json: data,
    });
  }

  invite(data: APIOrganizationUserInvite) {
    return super.postEmpty(`${this.baseUrl}invite/`, { json: data });
  }

  inviteMany(data: APIOrganizationUserInvite[]) {
    return super.postEmpty(`${this.baseUrl}invite/`, { json: data });
  }

  processInvite(data: APIProcessOrganizationInvite) {
    return super._post<APIProcessOrganizationInviteResponse>(`${this.baseUrl}process-invite/`, {
      json: data,
    });
  }

  deleteInvite(data: APIProcessOrganizationInvite) {
    return super.destroyEmpty(`${this.baseUrl}process-invite/`, {
      json: data,
    });
  }

  validateInvite(data: APIOrganizationUserInviteInput) {
    return super._post<APIOrganizationUserInviteValidation>(`${this.baseUrl}validate-invites/`, {
      json: data,
    });
  }

  validateInvites(data: APIOrganizationUserInviteInput[]) {
    return super._post<APIOrganizationUserInviteValidation[]>(`${this.baseUrl}validate-invites/`, {
      json: data,
    });
  }

  updateMember(slug: string, data: APIOrganizationUserInput) {
    return super._patch<APIIdentity>(`${this.baseUrl}${slug}/update-member/`, {
      json: data,
    });
  }

  deleteMember(slug: string, data: APIOrganizationUserInput) {
    return super.destroyEmpty(`${this.baseUrl}${slug}/update-member/`, {
      json: data,
    });
  }

  exportOpportunities() {
    return this._getBlob<Blob>(`${this.baseUrl}export-opportunities/`);
  }

  generateApplications() {
    return this._post<{ taskId: string }>(`${this.baseUrl}generate-applications-csv/`);
  }

  checkCsvStatus(id: string) {
    return this._get<{
      status: 'PENDING' | 'STARTED' | 'SUCCESS';
    }>(`${this.baseUrl}check-csv-status/?task_id=${id}`);
  }

  downloadCsv(id: string) {
    return this._getBlob<Blob>(`${this.baseUrl}download-csv/?task_id=${id}`);
  }
}
