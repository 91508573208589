import { AuthHeaderProps } from 'app/features/auth/header';
import { SignupType } from 'app/features/auth/signup/types';
import { ImageSourcePropType } from 'react-native';

type HexColor = `#${string}`;
type RgbColor = `rgb(${number},${number},${number})`;
type RgbaColor = `rgba(${number},${number},${number},${number})`;
type Color = HexColor | RgbColor | RgbaColor;

export type Theme = {
  readonly strings: {
    readonly name: string;
    readonly tradeWorldName: string;
    readonly scrollViewWithDatasetId: string;
    readonly loginPageType: AuthHeaderProps['pageType'];
    readonly orgSignupPageType: SignupType;
    readonly memberSignupPageType: SignupType;
    readonly signupStep3Highlight: string;
    readonly certificationsAndLicensesText: string;
    readonly careerExplorerCertificationsTitle: string;
  };
  readonly urls: {
    readonly signup: string;
    readonly orgSignup: string;
    readonly login: string;
    readonly logout: string;
    readonly newPasswordSuccess: string;
    readonly forgotPassword: string;
    readonly favicon: string;
  };
  readonly colors: {
    readonly primary1: Color;
    readonly primary2: Color;
    readonly primary3: Color;
    readonly primary4: Color;
    readonly secondary1: Color;
    readonly secondary2: Color;
    readonly neutral1: Color;
    readonly neutral2: Color;
    readonly neutral3: Color;
    readonly neutral4: Color;
    readonly neutral5: Color;
    readonly neutral6: Color;
    readonly neutral7: Color;
    readonly neutral8: Color;
    readonly neutral9: Color;
    readonly success: Color;
    readonly warning: Color;
    readonly alert: Color;
    readonly information: Color;
    readonly link: Color;
    readonly extra1: Color;
    readonly extra2: Color;
    readonly highlight: Color;
    readonly red: Color;
    readonly buttonFillBackground: Color;
    readonly buttonFillBackgroundHover: Color;
    readonly buttonFillText: Color;
    readonly buttonFillTextHover: Color;
  };
  readonly assets: {
    readonly logo: ImageSourcePropType;
    readonly logoSecondary: ImageSourcePropType;
    readonly logoBackground: ImageSourcePropType;
    readonly logoEducationExperience: ImageSourcePropType;
    readonly logoFooter: ImageSourcePropType;
    readonly logoHeader: ImageSourcePropType;
    readonly logoLight: ImageSourcePropType;
    readonly logoWhite: ImageSourcePropType;
    readonly logoSidebar: ImageSourcePropType;
    readonly logoSmallDark: ImageSourcePropType;
    readonly logoSmallGray: ImageSourcePropType;
    readonly logoSmallLight: ImageSourcePropType;
    readonly logoSmall: ImageSourcePropType;
    readonly logoTradeworld: ImageSourcePropType;
    readonly notFound: ImageSourcePropType;

    readonly applySuccessBg: ImageSourcePropType;
    readonly applySuccess: ImageSourcePropType;
    readonly authBackground: ImageSourcePropType;
    readonly badgeProfileCompleted: ImageSourcePropType;
    readonly badgeProfileIncomplete: ImageSourcePropType;
    readonly cardBoost: ImageSourcePropType;
    readonly cardGradient: ImageSourcePropType;
    readonly careerExplorerHeroBg: ImageSourcePropType;
    readonly careerExplorerQuizTile?: ImageSourcePropType; // NCCER only
    readonly careerExplorerSkillsHexagonLarge: ImageSourcePropType;
    readonly careerExplorerSkillsHexagonSmall: ImageSourcePropType;
    readonly careerExplorerSkillsHexagons: ImageSourcePropType;
    readonly certificateDefault: ImageSourcePropType;
    readonly checkIllustration: ImageSourcePropType;
    readonly claimProfile: ImageSourcePropType;
    readonly completionStickerLeft: ImageSourcePropType;
    readonly completionStickerRight: ImageSourcePropType;
    readonly defaultProfileBackground: ImageSourcePropType;
    readonly defaultAvatar: ImageSourcePropType;
    readonly documentCircle: ImageSourcePropType;
    readonly downloadAppModal: ImageSourcePropType;
    readonly experiencePic: ImageSourcePropType;
    readonly favoritePosts: ImageSourcePropType;
    readonly inviteFriends: ImageSourcePropType;
    readonly orgRequestSuccess: ImageSourcePropType;
    readonly pencilIllustration: ImageSourcePropType;
    readonly resumeBuilderBackground: ImageSourcePropType;
    readonly resumeBuilderSuccessBackground: ImageSourcePropType;
    readonly resumeIllustration: ImageSourcePropType;
    readonly sidebarPremium: ImageSourcePropType;
    readonly skillStampConfirmationBg: ImageSourcePropType;
    readonly successModalBg: ImageSourcePropType;
    readonly topicScreenBadge: ImageSourcePropType;
    readonly topicsScreenBadge: ImageSourcePropType;
    readonly tradeworldHeaderPolygons: ImageSourcePropType;
    readonly tradeworldHexagons: ImageSourcePropType;
    readonly tradeworldModalHexagons: ImageSourcePropType;
  };
};

const defaultAssets = {
  logo: require('../../../assets/default/logo.png'),
  logoSecondary: require('../../../assets/default/logo-secondary.png'),
  logoEducationExperience: require('../../../assets/default/logo-small-light.png'),
  logoBackground: require('../../../assets/default/logo-background.png'),
  logoFooter: require('../../../assets/default/logo-footer.png'),
  logoHeader: require('../../../assets/default/logo.png'),
  logoLight: require('../../../assets/default/logo-light.png'),
  logoWhite: require('../../../assets/default/logo-white.png'),
  logoSidebar: require('../../../assets/default/logo.png'),
  logoSmallDark: require('../../../assets/default/logo-small-dark.png'),
  logoSmallGray: require('../../../assets/default/logo-small-gray.png'),
  logoSmallLight: require('../../../assets/default/logo-small-light.png'),
  logoSmall: require('../../../assets/default/logo-small.png'),
  logoTradeworld: require('../../../assets/default/tradeworld/logo-small.png'),
  notFound: require('../../../assets/default/404.png'),

  applySuccessBg: require('../../../assets/default/apply-success-bg.png'),
  applySuccess: require('../../../assets/default/apply-success.png'),
  authBackground: require('../../../assets/default/auth-background.png'),
  badgeProfileCompleted: require('../../../assets/default/badge-profile-completed.png'),
  badgeProfileIncomplete: require('../../../assets/default/badge-profile-incomplete.png'),
  cardBoost: require('../../../assets/default/card-boost.png'),
  cardGradient: require('../../../assets/default/card-gradient.svg'),
  careerExplorerHeroBg: require('../../../assets/default/career-explorer/career-explorer-hero-bg.png'),
  careerExplorerSkillsHexagons: require('../../../assets/default/career-explorer/skills-hexagons-full.png'),
  careerExplorerSkillsHexagonLarge: require('../../../assets/default/career-explorer/skills-hexagon-large-full.png'),
  careerExplorerSkillsHexagonSmall: require('../../../assets/default/career-explorer/skills-hexagon-small.png'),
  claimProfile: require('../../../assets/default/hero-illustration.png'),
  certificateDefault: require('../../../assets/default/certificate-default.png'),
  checkIllustration: require('../../../assets/default/check-illustration.png'),
  completionStickerLeft: require('../../../assets/default/sticker-mechanic.png'),
  completionStickerRight: require('../../../assets/default/sticker-protective-equipment.png'),
  experiencePic: require('../../../assets/default/experience-pic.png'),
  defaultAvatar: require('../../../assets/default/avatar-skillhero.png'),
  defaultProfileBackground: require('../../../assets/default/backgrounds/default-bg.png'),
  documentCircle: require('../../../assets/default/document-circle.png'),
  downloadAppModal: require('../../../assets/default/mobile.png'),
  favoritePosts: require('../../../assets/default/favorite-posts.png'),
  inviteFriends: require('../../../assets/default/invite-friends.png'),
  orgRequestSuccess: require('../../../assets/default/org-request-success.png'),
  pencilIllustration: require('../../../assets/default/pencil-illustration.png'),
  resumeBuilderBackground: require('../../../assets/default/backgrounds/resume-builder-background.png'),
  resumeBuilderSuccessBackground: require('../../../assets/default/resume-builder-success-background.png'),
  resumeIllustration: require('../../../assets/default/resume-illustration.png'),
  sidebarPremium: require('../../../assets/default/workers.png'),
  skillStampConfirmationBg: require('../../../assets/default/skill-stamp-confirmation-bg.png'),
  successModalBg: require('../../../assets/default/success-modal-background.png'),
  topicScreenBadge: require('../../../assets/default/topic-screen-badge.png'),
  topicsScreenBadge: require('../../../assets/default/topics-screen-badge.png'),
  tradeworldHeaderPolygons: require('../../../assets/default/tradeworld/tradeworld-header-polygons.png'),
  tradeworldHexagons: require('../../../assets/default/tradeworld/trade-world-hexagons.png'),
  tradeworldModalHexagons: require('../../../assets/default/tradeworld/tradeworld-header-polygons.png'),
};

export const SKILLHERO_THEME: Theme = {
  strings: {
    name: 'SkillHero',
    tradeWorldName: 'TradeWorld',
    scrollViewWithDatasetId: 'select-container',
    loginPageType: 'login',
    orgSignupPageType: 'organization',
    memberSignupPageType: 'member',
    signupStep3Highlight: 'purple',
    certificationsAndLicensesText: 'Certifications & licenses',
    careerExplorerCertificationsTitle: 'Certifications',
  },
  urls: {
    signup: '/signup',
    orgSignup: '',
    login: '/login',
    logout: '/logout',
    newPasswordSuccess: '/new-password-success',
    forgotPassword: '/forgot-password',
    favicon: `/favicon.png`,
  },
  colors: {
    primary1: '#2A3439',
    primary2: '#40565E',
    primary3: '#1F282D',
    primary4: '#7097A0',
    secondary1: '#FEB649',
    secondary2: '#FF994F',
    neutral1: '#FFFFFF',
    neutral2: '#F9F9F9',
    neutral3: '#F4F4F4',
    neutral4: '#ECECEC',
    neutral5: '#D9D9D9',
    neutral6: '#A4A4A4',
    neutral7: '#787878',
    neutral8: '#1F282D',
    neutral9: '#000000',
    success: '#73A15D',
    warning: '#D98D1B',
    alert: '#D35730',
    information: '#3277D8',
    link: '#0E7AFE',
    extra1: '#5491BD',
    extra2: '#F4FBFE',
    highlight: '#FFFF00',
    red: '#FF0000',
    buttonFillBackground: '#FF994F',
    buttonFillBackgroundHover: '#FEB649',
    buttonFillText: '#1F282D',
    buttonFillTextHover: '#1F282D',
  },
  assets: defaultAssets,
};

export const NCCER_THEME: Theme = {
  strings: {
    name: 'CareerStarter',
    tradeWorldName: 'CraftCentral',
    scrollViewWithDatasetId: 'select-container-career-starter',
    loginPageType: 'careerStarterLogin',
    memberSignupPageType: 'careerStarter',
    orgSignupPageType: 'careerStarterOrganization',
    signupStep3Highlight: 'blue',
    certificationsAndLicensesText: 'Credentials, certifications & licenses',
    careerExplorerCertificationsTitle: 'Certifications and Credentials',
  },
  urls: {
    signup: '/careerstarter-signup',
    orgSignup: '/careerstarter-signup-organization',
    login: '/careerstarter-login',
    logout: '/careerstarter-logout',
    newPasswordSuccess: '/careerstarter-new-password-success',
    forgotPassword: '/careerstarter-forgot-password',
    favicon: '/favicon-career-starter.png',
  },
  colors: {
    primary1: '#172535',
    primary2: '#254A6A',
    primary3: '#060A0E',
    primary4: '#C1D1E7',
    secondary1: '#FD6A3C',
    secondary2: '#FB4214',
    neutral1: '#FFFFFF',
    neutral2: '#F9F9F9',
    neutral3: '#F4F4F4',
    neutral4: '#ECECEC',
    neutral5: '#D9D9D9',
    neutral6: '#A4A4A4',
    neutral7: '#787878',
    neutral8: '#252628',
    neutral9: '#000000',
    success: '#167F2F',
    warning: '#DDAD00',
    alert: '#9E1430',
    information: '#1344B5',
    link: '#0E7AFE',
    extra1: '#5491BD',
    extra2: '#F4FBFE',
    highlight: '#FFFF00',
    red: '#FF0000',
    buttonFillBackground: '#FD6A3C',
    buttonFillBackgroundHover: '#FB4214',
    buttonFillText: '#FFFFFF',
    buttonFillTextHover: '#FFFFFF',
  },
  assets: {
    ...defaultAssets,
    logo: require('../../../assets/nccer/logo.png'),
    logoBackground: require('../../../assets/nccer/logo-background.png'),
    logoEducationExperience: require('../../../assets/nccer/logo-education-experience.png'),
    logoFooter: require('../../../assets/nccer/logo-long.png'),
    logoHeader: require('../../../assets/nccer/logo-long.png'),
    logoLight: require('../../../assets/nccer/logo-light.png'),
    logoSidebar: require('../../../assets/nccer/logo.png'),
    logoSmallDark: require('../../../assets/nccer/logo-small.png'),
    logoSmallGray: require('../../../assets/nccer/logo-small-gray.png'),
    logoSmallLight: require('../../../assets/nccer/logo-small-gray.png'),
    logoSmall: require('../../../assets/nccer/logo-small.png'),
    logoTradeworld: require('../../../assets/nccer/tradeworld/logo-small.png'),

    applySuccess: require('../../../assets/nccer/apply-success.png'),
    authBackground: require('../../../assets/nccer/auth-background.png'),
    cardBoost: require('../../../assets/nccer/card-boost.png'),
    certificateDefault: require('../../../assets/nccer/certificate-default.png'),
    careerExplorerQuizTile: require('../../../assets/nccer/career-explorer/quiz-tile.png'),
    careerExplorerSkillsHexagonLarge: require('../../../assets/nccer/career-explorer/skills-hexagon-large-full.png'),
    careerExplorerSkillsHexagonSmall: require('../../../assets/nccer/career-explorer/skills-hexagon-small.png'),
    checkIllustration: require('../../../assets/nccer/check-illustration.png'),
    defaultAvatar: require('../../../assets/nccer/avatar-careerstarter.png'),
    defaultProfileBackground: require('../../../assets/nccer/backgrounds/default-bg.png'),
    inviteFriends: require('../../../assets/nccer/invite-friends.png'),
    orgRequestSuccess: require('../../../assets/nccer/org-request-success.png'),
    pencilIllustration: require('../../../assets/nccer/pencil-illustration.png'),
    resumeBuilderBackground: require('../../../assets/nccer/backgrounds/resume-builder-background.png'),
    resumeIllustration: require('../../../assets/nccer/resume-illustration.png'),
    skillStampConfirmationBg: require('../../../assets/nccer/skill-stamp-confirmation-bg.png'),
    topicScreenBadge: require('../../../assets/nccer/topic-screen-badge.png'),
    topicsScreenBadge: require('../../../assets/nccer/topics-screen-badge.png'),
    tradeworldHeaderPolygons: require('../../../assets/nccer/tradeworld/tradeworld-header-polygons.png'),
    tradeworldHexagons: require('../../../assets/nccer/tradeworld/trade-world-hexagons.png'),
    tradeworldModalHexagons: require('../../../assets/nccer/tradeworld/trade-world-modal-hexagons.png'),
  },
};

export const CHANNEL_THEME: { [key: string]: Theme } = {
  '': SKILLHERO_THEME,
  SkillHero: SKILLHERO_THEME,
  NCCER: NCCER_THEME,
};
