import { useAuth0 } from '@auth0/auth0-react';
import { firebaseSignout } from 'app/lib/firebase';
import React, { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import { useAuth0 as useNativeAuth0 } from 'react-native-auth0';
import useAsync from 'react-use/lib/useAsync';

/**
 * Signifies that the user is logged in using Auth0 social login.
 *
 * We set the refresh token to some non-empty value, even though social logins don't have a refresh token,
 * in order to simplify the `isAuthenticated` check.
 *
 */
const SOCIAL_REFRESH_TOKEN_PLACEHOLDER = '$__SOCIAL__$$';

type TokenContext = {
  token: string;
  refreshToken: string;
  identity: number | null;
  initializing: boolean;
  isSigningUp: boolean;
  clearIfJustInstalled?: () => Promise<boolean>;
  setToken: (token: string) => Promise<void>;
  deleteToken: () => Promise<void>;
  setRefreshToken: (refreshToken: string) => Promise<void>;
  deleteRefreshToken: () => Promise<void>;
  setIdentity: (identity: number) => Promise<void>;
  deleteIdentity: () => Promise<void>;
  logout: () => Promise<void>;
  setIsSigningUp: (value: boolean) => void;
  isAuthenticated: boolean;
};

const TokenContext = React.createContext<TokenContext>({
  token: '',
  refreshToken: '',
  identity: null,
  initializing: true,
  isSigningUp: false,
  setToken: async () => {},
  deleteToken: async () => {},
  setRefreshToken: async () => {},
  deleteRefreshToken: async () => {},
  setIdentity: async () => {},
  deleteIdentity: async () => {},
  setIsSigningUp: () => {},
  logout: async () => {},
  isAuthenticated: false,
});

export type TokenProviderProps = React.PropsWithChildren<{
  clearIfJustInstalled?: () => Promise<boolean>;
  getToken: () => Promise<string>;
  setToken: (token: string) => Promise<void>;
  deleteToken: () => Promise<void>;
  getRefreshToken: () => Promise<string>;
  setRefreshToken: (refreshToken: string) => Promise<void>;
  deleteRefreshToken: () => Promise<void>;
  getIdentity: () => Promise<number | null>;
  setIdentity: (identity: number) => Promise<void>;
  deleteIdentity: () => Promise<void>;
}>;

const TokenProvider: React.FC<TokenProviderProps> = ({
  children,
  clearIfJustInstalled,
  getToken,
  setToken,
  deleteToken,
  getRefreshToken,
  setRefreshToken,
  deleteRefreshToken,
  getIdentity,
  setIdentity,
  deleteIdentity,
}) => {
  const { getAccessTokenSilently, isAuthenticated: auth0IsAuthenticated, logout } = useAuth0();
  const { user, getCredentials, clearCredentials } = useNativeAuth0();

  const [initializing, setInitializing] = useState(true);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [token, setTokenState] = useState<string | undefined>();
  const [refreshToken, setRefreshTokenState] = useState<string | undefined>();
  const [identity, setIdentityState] = useState<number | null>(null);

  const handleSetToken = useCallback(
    async (token: string) => {
      await setToken(token);
      setTokenState(token);
    },
    [setToken]
  );

  const handleSetRefreshToken = useCallback(
    async (refreshToken: string) => {
      await setRefreshToken(refreshToken);
      setRefreshTokenState(refreshToken);
    },
    [setRefreshToken]
  );

  const handleDeleteToken = useCallback(async () => {
    await deleteToken();
    setTokenState('');
  }, [deleteToken]);

  const handleDeleteRefreshToken = useCallback(async () => {
    await deleteRefreshToken();
    setRefreshTokenState('');
  }, [deleteRefreshToken]);

  const handleDeleteIdentity = useCallback(async () => {
    await deleteIdentity();
    setIdentityState(null);
  }, [deleteIdentity]);

  /**
   * Logs the user out. Handles both Web and Native Auth0.
   */
  const handleLogout = useCallback(async () => {
    try {
      if (Platform.OS === 'web') {
        await logout({ openUrl: false });
      } else {
        await clearCredentials();
      }
    } catch (e) {
      console.warn({ e });
    }

    await handleDeleteRefreshToken();
    await handleDeleteToken();
    await handleDeleteIdentity();
  }, [clearCredentials, handleDeleteRefreshToken, handleDeleteToken, handleDeleteIdentity, logout]);

  /**
   * Handle token managed by `useAuth0` (Web).
   *
   * **No refresh token is available for Web Auth0.**
   */
  const initSocialWeb = useCallback(async () => {
    if (!auth0IsAuthenticated) {
      return false;
    }

    let tokenFromAuth0 = '';
    try {
      tokenFromAuth0 = await getAccessTokenSilently();
    } catch (e) {
      // The internal refresh token probably expired so Auth0 couldn't get a new access token.
      // We need to log the user out.
      await handleSetToken('');
      await handleSetRefreshToken('');
      return false;
    }

    await handleSetRefreshToken(SOCIAL_REFRESH_TOKEN_PLACEHOLDER);
    await handleSetToken(tokenFromAuth0);

    return true;
  }, [auth0IsAuthenticated, getAccessTokenSilently, handleSetRefreshToken, handleSetToken]);

  /**
   * Handles token managed by `useNativeAuth0` (Native).
   *
   * **No refresh token is available for Native Auth0.**
   */
  const initSocialNative = useCallback(async () => {
    if (!user) {
      return false;
    }

    let credentials: any;
    try {
      credentials = await getCredentials();
    } catch (e) {
      // The internal refresh token probably expired so Auth0 couldn't get a new access token.
      // We need to log the user out.
      await handleSetToken('');
      await handleSetRefreshToken('');
      return false;
    }

    await handleSetRefreshToken(credentials.refreshToken || SOCIAL_REFRESH_TOKEN_PLACEHOLDER);
    await handleSetToken(credentials.accessToken ?? '');

    return true;
  }, [getCredentials, handleSetRefreshToken, handleSetToken, user]);

  /** Handle token managed by the SkillHero API (Web & Native). */
  const initFromStore = useCallback(async () => {
    // Having no refresh token when logged in with a username/password combo
    // means we will not be able to renew the access token when it expires.
    // We need to log the user out by resetting the token.
    const refreshTokenFromStore = (await getRefreshToken()) ?? '';
    const tokenFromStore = refreshTokenFromStore ? (await getToken()) ?? '' : '';

    await handleSetRefreshToken(refreshTokenFromStore);
    await handleSetToken(tokenFromStore);

    return !!tokenFromStore;
  }, [getRefreshToken, getToken, handleSetRefreshToken, handleSetToken]);

  /**
   * Initialize the token state.
   */
  useAsync(async () => {
    if (await clearIfJustInstalled?.()) {
      await handleLogout();
    }

    try {
      // sourcery skip: merge-nested-ifs
      if (!(await initSocialWeb())) {
        if (!(await initSocialNative())) {
          if (!(await initFromStore())) {
            await handleLogout();
          }
        }
      }
    } finally {
      setInitializing(false);
    }
  }, [initSocialWeb, initSocialNative, initFromStore]);

  const handleSetIdentity = useCallback(
    async (identity: number) => {
      await firebaseSignout();
      await setIdentity(identity);
      setIdentityState(identity);
    },
    [setIdentity]
  );

  useAsync(async () => {
    setIdentityState(await getIdentity());
  }, [setIdentityState, getIdentity]);

  return (
    <TokenContext.Provider
      key={identity ?? 0}
      value={{
        token: token ?? '',
        refreshToken: refreshToken ?? '',
        identity: identity ?? null,
        initializing,
        isAuthenticated: !!refreshToken,
        isSigningUp,
        clearIfJustInstalled,
        setToken: handleSetToken,
        deleteToken: handleDeleteToken,
        setRefreshToken: handleSetRefreshToken,
        deleteRefreshToken: handleDeleteRefreshToken,
        setIdentity: handleSetIdentity,
        deleteIdentity: handleDeleteIdentity,
        logout: handleLogout,
        setIsSigningUp,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => React.useContext(TokenContext);

export default TokenProvider;
