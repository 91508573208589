import { PER_PAGE } from 'app/constants/blog';
import { createContext, useCallback, useState } from 'react';
import useMount from 'react-use/lib/useMount';

type BlogContext = {
  initialized: boolean;
  perPage: number;
  subscribed: boolean;
  handleSubscribe: () => void;
};

export const BlogContext = createContext<BlogContext>({
  initialized: false,
  perPage: PER_PAGE,
  subscribed: false,
  handleSubscribe: () => {},
});

type BlogProviderProps = React.PropsWithChildren<{
  setCookie: () => Promise<void>;
  getCookie: () => Promise<string | null | undefined>;
}>;

const BlogProvider: React.FC<BlogProviderProps> = ({ children, setCookie, getCookie }) => {
  const [initialized, setInitialized] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = useCallback(async () => {
    await setCookie();
    setSubscribed(true);
  }, [setCookie]);

  useMount(() => {
    const checkCookie = async () => {
      const subscribed = await getCookie();
      setSubscribed(subscribed === 'subscribed');
      setInitialized(true);
    };
    checkCookie();
  });

  return (
    <BlogContext.Provider
      value={{
        initialized,
        subscribed,
        perPage: subscribed ? PER_PAGE + 1 : PER_PAGE,
        handleSubscribe,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export default BlogProvider;
