import * as base from '../base';
import { APIIdentity } from './identities';

export type APINotification = {
  id: number;
  action: string;
  actors: APIIdentity[];
  contentType: string;
  objectId: number;
  isRead: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  postId?: number;
};

export type APIMessageNotification = {
  ids: string[];
  orgId?: string;
  message: string;
  orgMassMessage?: boolean;
};

export class NotificationsAPI extends base.CrudAPIBase<APINotification> {
  baseUrl = 'notifications/';

  message(data: APIMessageNotification) {
    return this._post<APIMessageNotification>(`${this.baseUrl}message/`, {
      json: data,
    });
  }

  read(id: number) {
    return this._post<APIMessageNotification>(`${this.baseUrl}${id}/read/`);
  }

  readAll() {
    return this.postEmpty(`${this.baseUrl}read-all/`);
  }
}
