import { createContext, useState } from 'react';

type ImageInfo = {
  url: string;
  width: number;
  height: number;
};

type PromoTileContext = {
  image?: ImageInfo;
  setImage: (value: ImageInfo) => void;
};

export const PromoTileContext = createContext<PromoTileContext>({
  image: undefined,
  setImage: () => {},
});

type PromoTileProviderProps = React.PropsWithChildren<{}>;

const PromoTileProvider: React.FC<PromoTileProviderProps> = ({ children }) => {
  const [image, setImage] = useState<ImageInfo>();

  return (
    <PromoTileContext.Provider
      value={{
        image,
        setImage,
      }}
    >
      {children}
    </PromoTileContext.Provider>
  );
};

export default PromoTileProvider;
