import appConfig from '../config/appConfig';

export class APIConfig {
  baseUrl = '';
  defaultLimit = 99999;
  timeout = 5 * 60000; // 5 minutes
  extraHeaders: Record<string, string> = {};

  /**
   * Initialize the configuration file.
   *
   * This must run before any API calls are made.
   */
  constructor() {
    this.baseUrl = appConfig.apiBaseUrl as string;
    this.defaultLimit = appConfig.apiDefaultLimit as number;
    this.timeout = appConfig.apiTimeout as number;
    this.extraHeaders = {};
  }
}
