import * as base from '../base';
export type APIFavorite = {
  id: number;
  post?: number | null;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export class FavoritesAPI extends base.CrudAPIBase<APIFavorite> {
  baseUrl = 'favorites/';

  clear() {
    return this.destroyEmpty(`${this.baseUrl}clear/`);
  }
}
