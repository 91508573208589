import * as base from '../base';

export type APIMessageQuery = {
  query: string;
  chatIds: string[];
};

export type APIValidChatIds = {
  validChats: string[];
  matchedPhrases: Record<string, string>;
};

export class SearchAPI extends base.APIBase {
  baseUrl = 'search/';

  messageSearch(data: APIMessageQuery) {
    return super._post<APIValidChatIds>('search/message-search/', { json: data });
  }
}
