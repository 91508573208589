import * as base from '../base';
import { APIReadOnlyBlockedUser } from './users';

export const UserReportReasons = [
  'impersonation',
  'spam',
  'illegal',
  'harrasment',
  'hate',
  'other',
] as const;

export type APIUserReportReasons = (typeof UserReportReasons)[number];

export type APIUserReport = {
  id: number;
  user: number;
  userInfo: APIReadOnlyBlockedUser;
  reason: APIUserReportReasons;
  details: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export type APIUserReportInput = Partial<
  Omit<APIUserReport, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export class UserReportsAPI extends base.CrudAPIBase<APIUserReport, APIUserReportInput> {
  baseUrl = 'user-reports/';
}
