import * as base from '../base';

export type APIInvitation = {
  id: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  type: 'Email' | 'Phone';
  name: string;
  email: string;
  phone: string;
  message: string;
};

export type APIInvitationInput = {
  type: 'Email' | 'Phone';
  name: string;
  email: string;
  phone: string;
  message: string;
};

export class InvitationAPI extends base.CrudAPIBase<APIInvitation, APIInvitationInput> {
  baseUrl = 'invitations/';

  invite(data: { instances: APIInvitationInput[] }) {
    return super._post<APIInvitation>(`${this.baseUrl}bulk-update/`, {
      json: data,
    });
  }
}
