import * as base from '../base';
import { APIBulkUpdateResult, APISearchParams } from '../types';

export type APIOrganizationMedia = {
  id: number;
  organization: number;
  file: string;
  muxPlaybackId: string;
  videoUrl: string;
  vimeoThumbnailUrl: string;
  mediaType: 'image' | 'video' | 'youtube_link' | 'vimeo_link';
  caption: string;
  isActive: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  youtubeVideoId: string | null;
  vimeoVideoId: string | null;
  isFeatured: boolean;
};

export type APIOrganizationMediaInput = Partial<
  Omit<
    APIOrganizationMedia,
    'created' | 'updated' | 'createdBy' | 'updatedBy' | 'isActive' | 'youtubeVideoId'
  >
>;

export interface APIOrganizationMediaSearchParams extends APISearchParams {
  organization?: string;
}

export class OrganizationMediaAPI extends base.CrudAPIBase<
  APIOrganizationMedia,
  APIOrganizationMediaInput,
  APIOrganizationMediaSearchParams
> {
  baseUrl = 'organizations/media/';
  extraOptions = {
    list: { public: true },
  };

  bulkUpdate(data: { instances: APIOrganizationMediaInput[] }) {
    return super._patch<APIBulkUpdateResult<APIOrganizationMedia>>(`${this.baseUrl}bulk-update/`, {
      json: data,
    });
  }
}
