import { View } from 'app/design/view';
import useProfile from 'app/hooks/useProfile';
import React from 'react';
import { ActivityIndicator, StyleProp, ViewStyle } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
};

export const Loading: React.FC<Props> = ({ containerStyle }) => {
  const styles = useStyles();
  const { theme } = useProfile();

  return (
    <View style={[styles.container, containerStyle]}>
      <ActivityIndicator size="large" color={theme.colors.primary1} />
    </View>
  );
};

const useStyles = CreateResponsiveStyle({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
