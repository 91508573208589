import * as base from '../base';

export type APIContactInput = {
  email: string;
  firstName: string;
  lastName: string;
  reason: 'Support' | 'Partnership' | 'Other';
  message: string;
  receiveEmails: boolean;
  token: string;
};

export class ContactAPI extends base.APIBase {
  baseUrl = 'contact/';

  submit(data: APIContactInput) {
    return super._post<APIContactInput>(this.baseUrl, {
      json: data,
    });
  }
}
