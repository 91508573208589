import * as base from '../base';
import { APIChannel } from './channels';
import { APIOrganizationProfileInvitePage } from './organizationProfiles';

export type APIOrganizationInvitePage = {
  updated: string;
  created: string;
  createdBy: number;
  updatedBy: number;
  id: number;
  organization: number;
  inviteCode: string;
  header: string;
  note: string;
  isCreated: boolean;
  isJoining: boolean;
  isJoined: boolean;
  hasNonMemberRole: boolean;
  organizationProfile: APIOrganizationProfileInvitePage;
  channel: APIChannel;
};

export type APIOrganizationInvitePageInput = {
  organization: number;
  header?: string;
  note?: string;
  isCreated?: boolean;
};

export class OrganizationInvitePageAPI extends base.CrudAPIBase<
  APIOrganizationInvitePage,
  APIOrganizationInvitePageInput
> {
  baseUrl = 'organizations/invite_page/';

  detail(id: number) {
    return this._get<APIOrganizationInvitePage>(`${this.baseUrl}${id}/`);
  }

  inviteCode(inviteCode: string) {
    return this._get<APIOrganizationInvitePage>(`${this.baseUrl}invite_code/${inviteCode}`);
  }
}
