import appConfig from 'app/config/appConfig';

export const PER_PAGE = 11;

export enum BlogCategoryContentfulEntryId {
  news = '4t8ycNBCwYmR0bwBy0WMKV',
  jobs = '3p9TAd7YuFkBedBsZYuoFV',
  recruiting = '11Agp8dBiM9gIfswle2JGE',
  training = 'tQrBFXMFHxakYE92wmW1Y',
  skillheroes = '6zkup8wPU6nPOIxUSsBqpj',
}

export enum ContentfulEntryIdBlogCategory {
  '4t8ycNBCwYmR0bwBy0WMKV' = 'news',
  '3p9TAd7YuFkBedBsZYuoFV' = 'jobs',
  '11Agp8dBiM9gIfswle2JGE' = 'recruiting',
  'tQrBFXMFHxakYE92wmW1Y' = 'training',
  '6zkup8wPU6nPOIxUSsBqpj' = 'skillheroes',
}

export enum ArticleCategoryContentfulEntryId {
  careerGuides = '57qsM3YHBxdKeOSqFI589m',
  engineering = '2R0bSdP1lBd9TPkDlw4kLv',
  genZ = '5puJNkfYhR8JpZFIxsvqJr',
  jobSearch = '1y5nMMu6WOkM3wiCVcg1t4',
  showcase = '4slUeoLeso5rB4BPQp2YgV',
  successStories = '6yZURu3rCiczC5YUeKQOzc',
  tools = '53dVtKOHT0PWsbeCOdvoKZ',
  unions = '6QEKeivNXn8J64iIxhpJNc',
}

export const BlogSEOContentfulEntryId = '47ZOV3lMQEdCpbhncvhNtr';

export const BLOG_MENU = [
  {
    title: 'SkillHero News',
    url: '/blog/news',
  },
  {
    title: 'Skilled Trades Jobs',
    url: '/blog/jobs',
  },
  {
    title: 'Recruiting',
    url: '/blog/recruiting',
  },
  {
    title: 'Training and Certifications',
    url: '/blog/training',
  },
  {
    title: 'SkillHeroes',
    url: '/blog/skillheroes',
  },
] as const;

export const BLOG_SOCIALS = [
  {
    name: 'SkillHero Website',
    alt: 'skillhero',
    url: appConfig.publicWebBaseUrl ?? 'https://skillhero.works',
    icon: require('../../../assets/default/logo-small-dark.png'),
  },
  {
    name: 'Article Link',
    alt: 'article',
    url: '',
    icon: require('../../../assets/link-2-dark.png'),
  },
];
