import { useToken } from 'app/provider/token';
import isEqual from 'lodash/isEqual';
import slice from 'lodash/slice';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { APIRequest } from '../api/types';

type SWROptions<Data, Error> = Parameters<typeof useSWR<Data, Error>>[2];

/**
 * Wrapper around useSWR to handle API requests from the `api` module.
 *
 * This hook will automatically re-run the request if the `apiRequest` changes.
 *
 * @param apiRequest An API call from the `api` module. If `null`, this hook will effectively be a no-op.
 * @param options `useSWR` options.
 * @returns `useSWR` result.
 */
export default function useApiRequest<T = Response>(
  apiRequest?: APIRequest<T> | null,
  options?: SWROptions<T, any>
) {
  const { identity } = useToken();
  const [apiRequestRef, setApiRequestRef] = useState(apiRequest);

  const [request, url, token, isPublic] = apiRequestRef ?? [null, null, false, null];

  // Compute the correct SWR key based on the request URL,
  // token and whether the token is required.
  const swrKey = useMemo(() => {
    if (url) {
      if (token) {
        return [url, token, identity];
      }
      if (isPublic) {
        return [url, null, null];
      }
    }
    return null;
  }, [identity, isPublic, token, url]);

  useEffect(() => {
    if (!isEqual(slice(apiRequest, 1, 4), slice(apiRequestRef, 1, 4))) {
      setApiRequestRef(apiRequest);
    }
  }, [apiRequest, apiRequestRef]);

  return useSWR<T, Error, any>(swrKey, request, options);
}
