import * as base from '../base';
import { APISearchParams } from '../types';
import { APIMinimalInternalOrganization } from './internalOrganizations';

export type APICertifications = {
  id: number;
  name: string;
  issuer: string;
  image: string;
  isActive: boolean;
  deleteDate: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  trades: string[];
  internalOrganization?: APIMinimalInternalOrganization;
};

export interface APICertificationSearchParams extends APISearchParams {
  trade?: string;
}

export class CertificationsAPI extends base.ReadOnlyAPIBase<
  APICertifications,
  APICertificationSearchParams
> {
  baseUrl = 'certifications/';
  extraOptions = {
    list: { public: true },
  };
}
