import * as base from '../base';

export type APIFollowing = {
  id: number | undefined;
  name: string;
  identity: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  followingType: 'topic' | 'organization' | 'member';
  memberProfile?: number | null;
  isMentor?: boolean;
  organizationProfile?: number | null;
  topic?: number | null;
  identifierSlug: string | null;
  email: string | null;
};

export type APIFollowingInput = Partial<
  Omit<APIFollowing, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export type APIConnectionCounts = {
  members: number;
  mentors: number;
  organizations: number;
  total: number;
};

export class FollowingsAPI extends base.CrudAPIBase<APIFollowing, APIFollowingInput> {
  baseUrl = 'followings/';

  fetchMyConnectionsCount(query: string = '') {
    return this._get<APIConnectionCounts>(`${this.baseUrl}my-connections-count/`, {
      searchParams: { query },
    });
  }
}
