import * as base from '../base';

export type APIIndustry = {
  id: number;
  name: string;
  isActive: boolean;
  deleteDate: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export class IndustriesAPI extends base.ReadOnlyAPIBase<APIIndustry> {
  baseUrl = 'attributes/industries/';
  extraOptions = {
    list: { public: true },
  };
}
