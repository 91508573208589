import * as base from '../base';
import { APIChannel } from './channels';
import { APIInternalSkillStamp } from './skillStamps';

export type APIEndorsement = {
  id: number;
  skillStampData: APIInternalSkillStamp;
  requestStatus: 'requested' | 'confirmed';
  skillStamp: number;
  identity: number;
  endorserFirstName: string;
  endorserLastName: string;
  endorserPositionTitle: string;
  endorserCompanyName: string;
  endorserEmail?: string;
  endorserPhone?: string;
  requestedVia: 'email' | 'phone';
  endorserRelationship?: string;
  note?: string;
  isOwner?: boolean;
  channel: APIChannel;
};

export type APIEndorsementInput = Partial<
  Omit<APIEndorsement, 'id' | 'skillStampData' | 'requestStatus'>
>;

export type APIEndorsementConfirmInput = Partial<
  Omit<
    APIEndorsementInput,
    | 'id'
    | 'skillStamp'
    | 'identity'
    | 'skillStampData'
    | 'requestStatus'
    | 'note'
    | 'endorserRelationship'
    | 'requestedVia'
  >
> & {
  endorserRelationship: string;
};

export class EndorsementsAPI extends base.CrudAPIBase<APIEndorsement, APIEndorsementInput> {
  baseUrl = 'endorsements/';
  extraOptions = {
    list: { public: true },
  };

  detail(requestCode: string) {
    return this._get<APIEndorsement>(`${this.baseUrl}${requestCode}/`);
  }

  validateRequest(data: APIEndorsementInput) {
    return this.postEmpty(`${this.baseUrl}validate-request/`, { json: data });
  }

  validate(data: APIEndorsementInput) {
    return this.postEmpty(`${this.baseUrl}validate/`, { json: data });
  }

  approve(requestCode: string, data: APIEndorsementConfirmInput) {
    return this._post<APIEndorsement>(`${this.baseUrl}${requestCode}/approve/`, {
      json: data,
    });
  }
}
