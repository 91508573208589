import * as base from '../base';
import { APIList, APISearchParams, Options } from '../types';
import { APILocation } from './locations';

export type APIInternalAttribute = {
  id: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  name: string;
  type: number;
  typeName: string;
};

export type APIInternalOrganization = {
  id: number;
  name: string;
  slug: string;
  profileSlug?: string;
  type:
    | 'Skilled Trades Employer'
    | 'Union'
    | 'Industry Association'
    | 'Education'
    | 'Public Sector';
  industry: string | null;
  logo: string;
  imageSizes?: {
    big?: string;
    medium?: string;
  };
  isActive: boolean;
  deleteDate: string | null;
  url?: string;
  description?: string;
  employeeCount?: number | null;
  revenue?: number | null;
  geolocationData: APILocation | null;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  keywords: APIInternalAttribute[];
  isFavorited: boolean;
};

export type APIMinimalInternalOrganization = {
  id: number;
  name: string;
  logo: string;
  slug: string;
};

export interface APIInternalOrganizationSearchParams extends APISearchParams {
  query?: string;
  name?: string;
  type?: APIInternalOrganization['type'] | '';
  industry?: string;
  trade?: string;
  location?: string;
  geolocation?: string;
  radius?: '' | number;
}

export class InternalOrganizationsAPI extends base.ReadOnlyAPIBase<
  APIInternalOrganization,
  APIInternalOrganizationSearchParams
> {
  baseUrl = 'internal-organizations/';
  extraOptions = {
    list: { public: true },
    detail: { public: true },
  };

  similar(
    slug: string,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams,
    options?: Options
  ) {
    return this._list<APIList<APIInternalOrganization>>(
      `${this.baseUrl}${slug}/similar/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  favorite(slug: string, identity: number) {
    return this.postEmpty(`${this.baseUrl}${slug}/favorite/`, { json: { identity } });
  }

  unfavorite(slug: string, identity: number) {
    return this.destroyEmpty(`${this.baseUrl}${slug}/favorite/`, { json: { identity } });
  }

  favorites(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIInternalOrganization>>(
      `${this.baseUrl}favorites/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  idList() {
    return this._get<number[]>(`${this.baseUrl}id_list/`);
  }

  slugList() {
    return this._get<string[]>(`${this.baseUrl}slug_list/`);
  }
}
