import { APIChannel } from 'app/api/routes/channels';
import { APIIdentity } from 'app/api/routes/identities';
import { APIProfile } from 'app/api/routes/profiles';
import { APIUser } from 'app/api/routes/users';
import useApi from 'app/hooks/useApi';
import { lazy } from 'react';
import { LogBox, Platform } from 'react-native';
import { SWRConfig, unstable_serialize } from 'swr';
import HashtagStatsProvider from './hashtag-stats';

const APIProvider = lazy(() => import('./api').then((module) => ({ default: module.APIProvider })));
const ProfileProvider = lazy(() =>
  import('./profile').then((module) => ({ default: module.ProfileProvider }))
);
const FirebaseNotificationsProvider = lazy(() =>
  import('./notifications').then((module) => ({ default: module.FirebaseNotificationsProvider }))
);
const SearchClientProvider = lazy(() =>
  import('./search').then((module) => ({ default: module.SearchClientProvider }))
);
const TrendingProvider = lazy(() =>
  import('./trending').then((module) => ({ default: module.TrendingProvider }))
);

type Props = React.PropsWithChildren<{
  fallback?: {
    token: string;
    me: APIUser;
    profile: APIProfile;
    identity: APIIdentity;
    channel: APIChannel;
  };
}>;

LogBox.ignoreLogs([
  'VirtualizedLists should never be nested',
  'StyleSheet.compose(a, b) is deprecated; use array syntax, i.e., [a,b].',
]);

export function Provider({ children, fallback }: Props) {
  // We cannot use `useApiRequest` yet, because `useApiRequest` depends on SWRConfig!
  const api = useApi();

  const swrProfileFallback = fallback
    ? {
        [unstable_serialize([api.users.fetchMe()[1], fallback.token])]: fallback.me,
        [unstable_serialize([api.profiles.fetchMe()[1], fallback.token])]: fallback.profile,
        [unstable_serialize([api.identities.fetchMe()[1], fallback.token])]: fallback.identity,
      }
    : {
        [unstable_serialize([api.users.fetchMe()[1], api.token])]: null,
        [unstable_serialize([api.profiles.fetchMe()[1], api.token])]: null,
        [unstable_serialize([api.profiles.fetchMe()[1], api.token])]: null,
      };

  if (Platform.OS === 'web' && !fallback) {
    return (
      <SWRConfig
        value={{
          fallback: swrProfileFallback,
          revalidateOnFocus: false,
          shouldRetryOnError: false,
        }}
      >
        <APIProvider>{children}</APIProvider>
      </SWRConfig>
    );
  }

  return (
    <SWRConfig
      value={{
        fallback: swrProfileFallback,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
      }}
    >
      <APIProvider>
        <ProfileProvider
          fallbackChannel={fallback?.channel}
          fallbackMe={fallback?.me}
          fallbackProfile={fallback?.profile}
          fallbackIdentity={fallback?.identity}
        >
          <FirebaseNotificationsProvider>
            <SearchClientProvider>
              <HashtagStatsProvider>
                <TrendingProvider>{children}</TrendingProvider>
              </HashtagStatsProvider>
            </SearchClientProvider>
          </FirebaseNotificationsProvider>
        </ProfileProvider>
      </APIProvider>
    </SWRConfig>
  );
}
