import { AuthAPI } from './routes/auth';
import { BackgroundsAPI } from './routes/backgrounds';
import { BadgesAPI } from './routes/badges';
import { BlogAPI } from './routes/blog';
import { CategoriesAPI } from './routes/categories';
import { CertificationsAPI } from './routes/certifications';
import { ChannelsAPI } from './routes/channels';
import { CommentsAPI } from './routes/comments';
import { ContactAPI } from './routes/contact';
import { EndorsementsAPI } from './routes/endorsements';
import { FavoritesAPI } from './routes/favorites';
import { FollowersAPI } from './routes/followers';
import { FollowingsAPI } from './routes/followings';
import { HashtagStatsAPI } from './routes/hashtagStats';
import { IdentitiesAPI } from './routes/identities';
import { IndustriesAPI } from './routes/industries';
import { InternalOrganizationsAPI } from './routes/internalOrganizations';
import { InvitationAPI } from './routes/invitations';
import { LocationsAPI } from './routes/locations';
import { NotificationsAPI } from './routes/notifications';
import { OpportunitiesAPI, OpportunityBoostAPI } from './routes/opportunities';
import { OpportunityApplicationAPI } from './routes/opportunityApplication';
import { OrganizationInviteAPI } from './routes/organizationInvite';
import { OrganizationInvitePageAPI } from './routes/organizationInvitePage';
import { OrganizationJoinAPI } from './routes/organizationJoin';
import { OrganizationLocationsAPI } from './routes/organizationLocations';
import { OrganizationMediaAPI } from './routes/organizationMedia';
import { OrganizationProfilesAPI } from './routes/organizationProfiles';
import { PositionsAPI } from './routes/positions';
import { PostReportsAPI } from './routes/postReports';
import { PostMediaAPI, PostsAPI } from './routes/posts';
import { ProfilesAPI } from './routes/profiles';
import { SearchAPI } from './routes/search';
import { ShippingAPI } from './routes/shipping';
import { SkillStampsAPI } from './routes/skillStamps';
import { SkillsAPI } from './routes/skills';
import { SubcategoriesAPI } from './routes/subcategories';
import { TopicMediaAPI, TopicsAPI } from './routes/topics';
import { TradePathsAPI } from './routes/tradePaths';
import { TradesAPI } from './routes/trades';
import { UserDevicesAPI } from './routes/userDevices';
import { UserReportsAPI } from './routes/userReports';
import { UsersAPI } from './routes/users';
import { VideosAPI } from './routes/video';

export default class API {
  readonly token: string;
  readonly refreshToken: string;

  readonly auth: AuthAPI;
  readonly badges: BadgesAPI;
  readonly categories: CategoriesAPI;
  readonly certifications: CertificationsAPI;
  readonly channels: ChannelsAPI;
  readonly comments: CommentsAPI;
  readonly followers: FollowersAPI;
  readonly followings: FollowingsAPI;
  readonly industries: IndustriesAPI;
  readonly identities: IdentitiesAPI;
  readonly locations: LocationsAPI;
  readonly notifications: NotificationsAPI;
  readonly opportunities: OpportunitiesAPI;
  readonly opportunityApplications: OpportunityApplicationAPI;
  readonly opportunityBoosts: OpportunityBoostAPI;
  readonly internalOrganizations: InternalOrganizationsAPI;
  readonly organizationLocations: OrganizationLocationsAPI;
  readonly organizationMedia: OrganizationMediaAPI;
  readonly organizationProfiles: OrganizationProfilesAPI;
  readonly organizationInvitePage: OrganizationInvitePageAPI;
  readonly organizationInvite: OrganizationInviteAPI;
  readonly organizationJoin: OrganizationJoinAPI;
  readonly positions: PositionsAPI;
  readonly posts: PostsAPI;
  readonly postMedia: PostMediaAPI;
  readonly postReports: PostReportsAPI;
  readonly profiles: ProfilesAPI;
  readonly search: SearchAPI;
  readonly skills: SkillsAPI;
  readonly subcategories: SubcategoriesAPI;
  readonly topics: TopicsAPI;
  readonly topicMedia: TopicMediaAPI;
  readonly tradePaths: TradePathsAPI;
  readonly users: UsersAPI;
  readonly userReports: UserReportsAPI;
  readonly userDevices: UserDevicesAPI;
  readonly favorites: FavoritesAPI;
  readonly videos: VideosAPI;
  readonly blog: BlogAPI;
  readonly invitations: InvitationAPI;
  readonly shipping: ShippingAPI;
  readonly contact: ContactAPI;
  readonly endorsements: EndorsementsAPI;
  readonly skillStamps: SkillStampsAPI;
  readonly trades: TradesAPI;
  readonly backgrounds: BackgroundsAPI;
  readonly hashtagStats: HashtagStatsAPI;

  constructor(options?: {
    token?: string;
    refreshToken?: string;
    identity?: number;
    setToken: (token: string) => Promise<void>;
    setRefreshToken: (token: string) => Promise<void>;
  }) {
    this.token = options?.token ?? '';
    this.refreshToken = options?.refreshToken ?? '';

    this.auth = new AuthAPI(options);
    this.badges = new BadgesAPI(options);
    this.categories = new CategoriesAPI(options);
    this.certifications = new CertificationsAPI(options);
    this.channels = new ChannelsAPI(options);
    this.comments = new CommentsAPI(options);
    this.followers = new FollowersAPI(options);
    this.followings = new FollowingsAPI(options);
    this.industries = new IndustriesAPI(options);
    this.identities = new IdentitiesAPI(options);
    this.locations = new LocationsAPI(options);
    this.notifications = new NotificationsAPI(options);
    this.opportunities = new OpportunitiesAPI(options);
    this.opportunityApplications = new OpportunityApplicationAPI(options);
    this.opportunityBoosts = new OpportunityBoostAPI(options);
    this.internalOrganizations = new InternalOrganizationsAPI(options);
    this.organizationLocations = new OrganizationLocationsAPI(options);
    this.organizationMedia = new OrganizationMediaAPI(options);
    this.organizationProfiles = new OrganizationProfilesAPI(options);
    this.organizationInvitePage = new OrganizationInvitePageAPI(options);
    this.organizationInvite = new OrganizationInviteAPI(options);
    this.organizationJoin = new OrganizationJoinAPI(options);
    this.positions = new PositionsAPI(options);
    this.posts = new PostsAPI(options);
    this.postMedia = new PostMediaAPI(options);
    this.postReports = new PostReportsAPI(options);
    this.profiles = new ProfilesAPI(options);
    this.search = new SearchAPI(options);
    this.skills = new SkillsAPI(options);
    this.subcategories = new SubcategoriesAPI(options);
    this.topics = new TopicsAPI(options);
    this.topicMedia = new TopicMediaAPI(options);
    this.tradePaths = new TradePathsAPI(options);
    this.users = new UsersAPI(options);
    this.userDevices = new UserDevicesAPI(options);
    this.userReports = new UserReportsAPI(options);
    this.favorites = new FavoritesAPI(options);
    this.videos = new VideosAPI(options);
    this.blog = new BlogAPI();
    this.invitations = new InvitationAPI(options);
    this.shipping = new ShippingAPI(options);
    this.contact = new ContactAPI();
    this.endorsements = new EndorsementsAPI(options);
    this.skillStamps = new SkillStampsAPI(options);
    this.trades = new TradesAPI(options);
    this.backgrounds = new BackgroundsAPI(options);
    this.hashtagStats = new HashtagStatsAPI(options);
  }
}
