import * as base from '../base';

export type APIHashtagStats = {
  posts: number;
  comments: number;
  likedComments: number;
  likedPosts: number;
};

export class HashtagStatsAPI extends base.ReadOnlyAPIBase<APIHashtagStats> {
  baseUrl = 'hashtag/';

  fetchStats(query: string) {
    const params = new URLSearchParams();
    params.append('hashtag', query);
    return this._get<APIHashtagStats>(`${this.baseUrl}stats/?${params.toString()}`);
  }
}
