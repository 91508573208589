import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

export const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refreshToken';
const IDENTITY_KEY = 'identity';
const UTM_KEY = 'utmCode';
const UTM_SET_ORG_KEY = 'shouldSetUtmOrg';
const TRADEWORLD_KEY = 'tradeworld';
const BLOG_SUBSCRIBED_KEY = 'skillhero-blog-subscribed';
export const SIGNUP_RANDOMIZED_KEY = 'signup-randomized';
export const CHANNEL_KEY = 'channel';

// Access Token Cookie

export function getTokenCookie(context?: GetServerSidePropsContext): string {
  return getCookie(TOKEN_KEY, context) as string;
}

export function setTokenCookie(token: string, context?: GetServerSidePropsContext): void {
  setCookie(TOKEN_KEY, token, context);
}

export function deleteTokenCookie(context?: GetServerSidePropsContext): void {
  deleteCookie(TOKEN_KEY, context);
}

export async function getTokenCookieAsync(context?: GetServerSidePropsContext): Promise<string> {
  return Promise.resolve(getTokenCookie(context));
}

export async function setTokenCookieAsync(
  token: string,
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setTokenCookie(token, context));
}

export async function deleteTokenCookieAsync(context?: GetServerSidePropsContext): Promise<void> {
  return Promise.resolve(deleteTokenCookie(context));
}

// Refresh Token Cookie

export function getRefreshTokenCookie(context?: GetServerSidePropsContext): string {
  return getCookie(REFRESH_TOKEN_KEY, context) as string;
}

export function setRefreshTokenCookie(token: string, context?: GetServerSidePropsContext): void {
  setCookie(REFRESH_TOKEN_KEY, token, context);
}

export function deleteRefreshTokenCookie(context?: GetServerSidePropsContext): void {
  deleteCookie(REFRESH_TOKEN_KEY, context);
}

export async function getRefreshTokenCookieAsync(
  context?: GetServerSidePropsContext
): Promise<string> {
  return Promise.resolve(getRefreshTokenCookie(context));
}

export async function setRefreshTokenCookieAsync(
  token: string,
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setRefreshTokenCookie(token, context));
}

export async function deleteRefreshTokenCookieAsync(
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(deleteRefreshTokenCookie(context));
}

// Identity Cookie

export function getIdentityCookie(context?: GetServerSidePropsContext) {
  const identity = getCookie(IDENTITY_KEY, context);
  return identity ? Number(identity) : null;
}

export function setIdentityCookie(identity: number, context?: GetServerSidePropsContext): void {
  setCookie(IDENTITY_KEY, identity, context);
}

export function deleteIdentityCookie(context?: GetServerSidePropsContext): void {
  deleteCookie(IDENTITY_KEY, context);
}

export async function getIdentityCookieAsync(context?: GetServerSidePropsContext) {
  return Promise.resolve(getIdentityCookie(context));
}

export async function setIdentityCookieAsync(
  identity: number,
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setIdentityCookie(identity, context));
}

export async function deleteIdentityCookieAsync(
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(deleteIdentityCookie(context));
}

// UTM Cookie

function setUTMCodeCookie(code: string, context?: GetServerSidePropsContext): void {
  setCookie(UTM_KEY, code, context);
}

function getUTMCodeCookie(context?: GetServerSidePropsContext) {
  return getCookie(UTM_KEY, context);
}

function deleteUTMCodeCookie(context?: GetServerSidePropsContext) {
  deleteCookie(UTM_KEY, context);
}

export async function setUTMCodeCookieAsync(
  code: string,
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setUTMCodeCookie(code, context));
}

export async function getUTMCodeCookieAsync(
  context?: GetServerSidePropsContext
): Promise<string | undefined> {
  return Promise.resolve(getUTMCodeCookie(context));
}

export async function deleteUTMCodeCookieAsync(context?: GetServerSidePropsContext): Promise<void> {
  return Promise.resolve(deleteUTMCodeCookie(context));
}

// UTM Org Cookie

function setUTMOrgCookie(shouldUpdate: string, context?: GetServerSidePropsContext): void {
  setCookie(UTM_SET_ORG_KEY, shouldUpdate, context);
}

function getUTMOrgCookie(context?: GetServerSidePropsContext) {
  return getCookie(UTM_SET_ORG_KEY, context);
}

function deleteUTMOrgCookie(context?: GetServerSidePropsContext) {
  deleteCookie(UTM_SET_ORG_KEY, context);
}

export async function setUTMOrgCookieAsync(
  shouldUpdate: string,
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setUTMOrgCookie(shouldUpdate, context));
}

export async function getUTMOrgCookieAsync(
  context?: GetServerSidePropsContext
): Promise<string | undefined> {
  return Promise.resolve(getUTMOrgCookie(context));
}

export async function deleteUTMOrgCookieAsync(context?: GetServerSidePropsContext): Promise<void> {
  return Promise.resolve(deleteUTMOrgCookie(context));
}

// TradeWorld Cookie

function setTradeWorldCookie(context?: GetServerSidePropsContext): void {
  setCookie(TRADEWORLD_KEY, TRADEWORLD_KEY, context);
}

function getTradeWorldCookie(context?: GetServerSidePropsContext) {
  return getCookie(TRADEWORLD_KEY, context);
}

function deleteTradeWorldeCookie(context?: GetServerSidePropsContext) {
  deleteCookie(TRADEWORLD_KEY, context);
}

export async function setTradeWorldCookieAsync(context?: GetServerSidePropsContext): Promise<void> {
  return Promise.resolve(setTradeWorldCookie(context));
}

export async function getTradeWorldCookieAsync(
  context?: GetServerSidePropsContext
): Promise<string | undefined> {
  return Promise.resolve(getTradeWorldCookie(context));
}

export async function deleteTradeWorldCookieAsync(
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(deleteTradeWorldeCookie(context));
}

// BLOG
function setBlogSubscribedCookie(context?: GetServerSidePropsContext): void {
  return setCookie(BLOG_SUBSCRIBED_KEY, 'subscribed', context);
}

function getBlogSubscribedCookie(context?: GetServerSidePropsContext) {
  return getCookie(BLOG_SUBSCRIBED_KEY, context);
}

export async function setBlogSubscribedCookieAsync(
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setBlogSubscribedCookie(context));
}

export async function getBlogSubscribedCookieAsync(
  context?: GetServerSidePropsContext
): Promise<string | undefined> {
  return Promise.resolve(getBlogSubscribedCookie(context));
}

// Signup Randomized Cookie

function setSignupRandomizedCookie(page: number, context?: GetServerSidePropsContext): void {
  setCookie(SIGNUP_RANDOMIZED_KEY, page);
}

function deleteSignupRandomizedCookie(context?: GetServerSidePropsContext) {
  deleteCookie(SIGNUP_RANDOMIZED_KEY, context);
}

export async function setSignupRandomizedCookieAsync(
  page: number,
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setSignupRandomizedCookie(page, context));
}

export async function deleteSignupRandomizedCookieAsync(
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(deleteSignupRandomizedCookie(context));
}

// Channel Cookie

function setChannelCookie(channel: string, context?: GetServerSidePropsContext): void {
  setCookie(CHANNEL_KEY, channel);
}

export function getChannelCookie(context?: GetServerSidePropsContext) {
  return getCookie(CHANNEL_KEY, context);
}

function deleteChannelCookie(context?: GetServerSidePropsContext) {
  deleteCookie(CHANNEL_KEY, context);
}

export async function setChannelCookieAsync(
  channel: string,
  context?: GetServerSidePropsContext
): Promise<void> {
  return Promise.resolve(setChannelCookie(channel, context));
}

export async function getChannelCookieAsync(
  context?: GetServerSidePropsContext
): Promise<string | undefined> {
  return Promise.resolve(getChannelCookie(context));
}

export async function deleteChannelCookieAsync(context?: GetServerSidePropsContext): Promise<void> {
  return Promise.resolve(deleteChannelCookie(context));
}
