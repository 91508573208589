import * as base from '../base';
import { APIList, APISearchParams, Options } from '../types';

export type APIPosition = {
  id: number;
  name: string;
  isActive: boolean;
  deleteDate: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export class PositionsAPI extends base.ReadOnlyAPIBase<APIPosition> {
  baseUrl = 'positions/';
  extraOptions = {
    list: { public: true },
  };
}
