import 'raf/polyfill';
import 'setimmediate';

import { enableLegacyWebImplementation } from 'react-native-gesture-handler';
enableLegacyWebImplementation(true);

import { Auth0Provider } from '@auth0/auth0-react';
import { Loading } from 'app/components/loading';
import appConfig from 'app/config/appConfig';
import { Provider } from 'app/provider';
import BlogProvider from 'app/provider/blog';
import CareerExplorerSkillsProvider from 'app/provider/career-explorer';
import PromoTileProvider from 'app/provider/promo-tile';
import SchoolsLandingProvider from 'app/provider/schools-landing';
import SignupProvider from 'app/provider/signup';
import { SSRProvider } from 'app/provider/ssr-provider';
import TokenProvider from 'app/provider/token';
import TradeWorldProvider from 'app/provider/trade-world';
import UTMProvider from 'app/provider/utm';
import { getCookie, setCookie } from 'cookies-next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { lazy } from 'react';
import { BreakpointsProvider } from 'rn-responsive-styles';
import {
  deleteChannelCookieAsync,
  deleteIdentityCookieAsync,
  deleteRefreshTokenCookieAsync,
  deleteSignupRandomizedCookieAsync,
  deleteTokenCookieAsync,
  deleteTradeWorldCookieAsync,
  deleteUTMCodeCookieAsync,
  deleteUTMOrgCookieAsync,
  getBlogSubscribedCookieAsync,
  getIdentityCookieAsync,
  getRefreshTokenCookieAsync,
  getTokenCookieAsync,
  getTradeWorldCookieAsync,
  getUTMCodeCookieAsync,
  getUTMOrgCookieAsync,
  setBlogSubscribedCookieAsync,
  setChannelCookieAsync,
  setIdentityCookieAsync,
  setRefreshTokenCookieAsync,
  setSignupRandomizedCookieAsync,
  setTokenCookieAsync,
  setTradeWorldCookieAsync,
  setUTMCodeCookieAsync,
  setUTMOrgCookieAsync,
} from '../utils/cookies';

import '../global.css';

const DownloadAppModal = lazy(() =>
  import('app/components/download-app-modal').then((mod) => ({ default: mod.DownloadAppModal }))
);

const fixReanimatedIssue = () => {
  // FIXME remove this once this reanimated fix gets released
  // https://github.com/software-mansion/react-native-reanimated/issues/3355
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window._frameTimestamp = null;
  }
};
fixReanimatedIssue();

const auth0Domain = `${appConfig.auth0Domain}`;
const auth0ClientId = `${appConfig.auth0ClientId}`;
const auth0RedirectUri = `${appConfig.auth0RedirectUri}`;
const auth0Audience = `${appConfig.auth0Audience}`;

function MyApp({ Component, pageProps }: AppProps) {
  // NOTE: `useToken`, `useApi` and `useApiRequest` require both `Auth0Provider` and `TokenProvider`,
  //       so you should not call any of those hooks here!

  return (
    <>
      <Head>
        <title>SkillHero</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: auth0RedirectUri,
          audience: auth0Audience,
        }}
      >
        <TokenProvider
          getToken={getTokenCookieAsync}
          setToken={setTokenCookieAsync}
          deleteToken={deleteTokenCookieAsync}
          getRefreshToken={getRefreshTokenCookieAsync}
          setRefreshToken={setRefreshTokenCookieAsync}
          deleteRefreshToken={deleteRefreshTokenCookieAsync}
          getIdentity={getIdentityCookieAsync}
          setIdentity={setIdentityCookieAsync}
          deleteIdentity={deleteIdentityCookieAsync}
        >
          <UTMProvider
            setUTMCode={setUTMCodeCookieAsync}
            getUTMCode={getUTMCodeCookieAsync}
            deleteUTMCode={deleteUTMCodeCookieAsync}
            setUTMOrg={setUTMOrgCookieAsync}
            getUTMOrg={getUTMOrgCookieAsync}
            deleteUTMOrg={deleteUTMOrgCookieAsync}
          >
            <TradeWorldProvider
              setCookie={setTradeWorldCookieAsync}
              getCookie={getTradeWorldCookieAsync}
              deleteCookie={deleteTradeWorldCookieAsync}
            >
              <BlogProvider
                setCookie={setBlogSubscribedCookieAsync}
                getCookie={getBlogSubscribedCookieAsync}
              >
                <CareerExplorerSkillsProvider>
                  <SignupProvider
                    _setRandomized={setSignupRandomizedCookieAsync}
                    _deleteRandomized={deleteSignupRandomizedCookieAsync}
                    _setChannel={setChannelCookieAsync}
                    _deleteChannel={deleteChannelCookieAsync}
                  >
                    <SchoolsLandingProvider>
                      <PromoTileProvider>
                        <Provider fallback={pageProps}>
                          <SSRProvider
                            placeholder={
                              <Loading containerStyle={{ width: '100%', height: '100%' }} />
                            }
                          >
                            <BreakpointsProvider breakpoints={[1419, 1179, 1023, 767]}>
                              <DownloadAppModal getCookie={getCookie} setCookie={setCookie} />
                              <Component {...pageProps} />
                            </BreakpointsProvider>
                          </SSRProvider>
                        </Provider>
                      </PromoTileProvider>
                    </SchoolsLandingProvider>
                  </SignupProvider>
                </CareerExplorerSkillsProvider>
              </BlogProvider>
            </TradeWorldProvider>
          </UTMProvider>
        </TokenProvider>
      </Auth0Provider>
    </>
  );
}

export default MyApp;
