import * as base from '../base';

export type APIMuxUploadCreate = {
  id: string;
  url: string;
};

export type APIMuxObject = {
  upload?: {
    status: string;
    url: string;
    assetId?: string;
  };
  asset?: {
    id: string;
    status: string;
    errors: string[];
    playbackIds: {
      id: string;
    }[];
  };
};

export class VideosAPI extends base.APIBase {
  createMuxUpload(type: 'video/*') {
    return this._post<APIMuxUploadCreate>('mux/upload/', {
      json: {
        type,
      },
    });
  }

  checkStatus(uploadId: string) {
    return this._get<APIMuxObject>(`mux/status/${uploadId}/`);
  }

  fetchAsset(assetId: string) {
    return this._get<APIMuxObject>(`mux/assets/${assetId}/`);
  }

  async uploadVideo(
    muxUrl: string,
    file: File | Blob,
    onProgress: (event: ProgressEvent<EventTarget>) => void,
    onSuccess: () => void,
    signal?: AbortSignal
  ) {
    await new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      const abort = () => {
        xhr.abort();
      };

      if (signal) {
        signal.addEventListener('abort', abort);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            signal.removeEventListener('abort', abort);
          }
        };
      }

      xhr.open('PUT', muxUrl, true);
      xhr.upload.onprogress = onProgress;
      xhr.addEventListener('load', () => {
        if (xhr.status === 200) {
          resolve();
        } else {
          reject(new Error(`Failed to upload video: ${xhr.status}`));
        }
      });

      xhr.send(file);
    });

    onSuccess();
  }
}
