import { ConfigEnvironment } from './type';
import { getSetting } from './util';

const LOCAL: ConfigEnvironment = {
  CHANNEL: process.env.CHANNEL ?? getSetting('CHANNEL'),

  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL ?? getSetting('NEXT_PUBLIC_API_URL'),
  NEXT_PUBLIC_NAME: process.env.NEXT_PUBLIC_NAME ?? getSetting('NEXT_PUBLIC_NAME'),
  NEXT_PUBLIC_SENTRY_DSN:
    process.env.NEXT_PUBLIC_SENTRY_DSN ?? getSetting('NEXT_PUBLIC_SENTRY_DSN'),
  NEXT_PUBLIC_WEB_BASE_URL:
    process.env.NEXT_PUBLIC_WEB_BASE_URL ?? getSetting('NEXT_PUBLIC_WEB_BASE_URL'),
  NEXT_PUBLIC_LANDING_BASE_URL:
    process.env.NEXT_PUBLIC_LANDING_BASE_URL ?? getSetting('NEXT_PUBLIC_LANDING_BASE_URL'),

  // Feature flags
  NEXT_PUBLIC_SKILLHERO_PASSWORDLESS_AUTH_FLAG:
    process.env.NEXT_PUBLIC_SKILLHERO_PASSWORDLESS_AUTH_FLAG ??
    getSetting('NEXT_PUBLIC_SKILLHERO_PASSWORDLESS_AUTH_FLAG'),

  // Contentful Credentials
  NEXT_PUBLIC_CONTENTFUL_SPACE_ID:
    process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? getSetting('NEXT_PUBLIC_CONTENTFUL_SPACE_ID'),
  NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID:
    process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID'),
  NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_API_KEY:
    process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_API_KEY ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_API_KEY'),
  NEXT_PUBLIC_CONTENTFUL_CONTENT_MANAGEMENT_API_KEY:
    process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_MANAGEMENT_API_KEY ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_CONTENT_MANAGEMENT_API_KEY'),
  NEXT_PUBLIC_CONTENTFUL_CONTENT_PREVIEW_API_KEY:
    process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_PREVIEW_API_KEY ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_CONTENT_PREVIEW_API_KEY'),

  // Contentful Landing Page Request Parameters
  NEXT_PUBLIC_CONTENTFUL_LANDING_CONTENT_TYPE:
    process.env.NEXT_PUBLIC_CONTENTFUL_LANDING_CONTENT_TYPE ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_LANDING_CONTENT_TYPE'),
  NEXT_PUBLIC_CONTENTFUL_LANDING_FIELD_NAME:
    process.env.NEXT_PUBLIC_CONTENTFUL_LANDING_FIELD_NAME ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_LANDING_FIELD_NAME'),
  NEXT_PUBLIC_CONTENTFUL_LANDING_TARGET_ENTRY_ID:
    process.env.NEXT_PUBLIC_CONTENTFUL_LANDING_TARGET_ENTRY_ID ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_LANDING_TARGET_ENTRY_ID'),

  // Contentful Landing Page Request Parameters
  NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_CONTENT_TYPE:
    process.env.NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_CONTENT_TYPE ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_CONTENT_TYPE'),
  NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_FIELD_NAME:
    process.env.NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_FIELD_NAME ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_FIELD_NAME'),
  NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_TARGET_ENTRY_ID:
    process.env.NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_TARGET_ENTRY_ID ??
    getSetting('NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_TARGET_ENTRY_ID'),

  // Contentful Embeds via Embedly Credentials
  NEXT_PUBLIC_EMBEDLY_API_KEY:
    process.env.NEXT_PUBLIC_EMBEDLY_API_KEY ?? getSetting('NEXT_PUBLIC_EMBEDLY_API_KEY'),

  // Auth0
  NEXT_PUBLIC_AUTH0_DOMAIN:
    process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? getSetting('NEXT_PUBLIC_AUTH0_DOMAIN'),
  NEXT_PUBLIC_AUTH0_CLIENT_ID:
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? getSetting('NEXT_PUBLIC_AUTH0_CLIENT_ID'),
  NEXT_PUBLIC_AUTH0_AUDIENCE:
    process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ?? getSetting('NEXT_PUBLIC_AUTH0_AUDIENCE'),
  NEXT_PUBLIC_AUTH0_REDIRECT_URI:
    process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI ?? getSetting('NEXT_PUBLIC_AUTH0_REDIRECT_URI'),

  // Chameleon
  NEXT_PUBLIC_CHAMELEON_APP_ID:
    process.env.NEXT_PUBLIC_CHAMELEON_APP_ID ?? getSetting('NEXT_PUBLIC_CHAMELEON_APP_ID'),

  // Firebase
  NEXT_PUBLIC_FIREBASE_API_KEY:
    process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? getSetting('NEXT_PUBLIC_FIREBASE_API_KEY'),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? getSetting('NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN'),
  NEXT_PUBLIC_FIREBASE_PROJECT_ID:
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? getSetting('NEXT_PUBLIC_FIREBASE_PROJECT_ID'),
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ??
    getSetting('NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET'),
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ??
    getSetting('NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID'),
  NEXT_PUBLIC_FIREBASE_APP_ID:
    process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? getSetting('NEXT_PUBLIC_FIREBASE_APP_ID'),
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ??
    getSetting('NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID'),
  NEXT_PUBLIC_FIREBASE_VAPID_PUBLIC_KEY:
    process.env.NEXT_PUBLIC_FIREBASE_VAPID_PUBLIC_KEY ??
    getSetting('NEXT_PUBLIC_FIREBASE_VAPID_PUBLIC_KEY'),

  // Algolia Search Credentials
  NEXT_PUBLIC_ALGOLIA_APP_ID:
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? getSetting('NEXT_PUBLIC_ALGOLIA_APP_ID'),
  NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY:
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ??
    getSetting('NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY'),
  NEXT_PUBLIC_ALGOLIA_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME ?? getSetting('NEXT_PUBLIC_ALGOLIA_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_PROFILE_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_PROFILE_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_PROFILE_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_ORGANIZATION_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_ORGANIZATION_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_ORGANIZATION_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_POST_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_POST_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_POST_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_TOPIC_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_TOPIC_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_TOPIC_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_OPPORTUNITY_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_OPPORTUNITY_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_OPPORTUNITY_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_SKILL_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_SKILL_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_SKILL_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_BLOG_POST_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_BLOG_POST_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_BLOG_POST_INDEX_NAME'),
  NEXT_PUBLIC_ALGOLIA_INTERNAL_ORGANIZATIONS_INDEX_NAME:
    process.env.NEXT_PUBLIC_ALGOLIA_INTERNAL_ORGANIZATIONS_INDEX_NAME ??
    getSetting('NEXT_PUBLIC_ALGOLIA_INTERNAL_ORGANIZATIONS_INDEX_NAME'),

  // Google Tag Manager
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID ?? getSetting('NEXT_PUBLIC_GTM_ID'),

  // HCaptcha
  NEXT_PUBLIC_HCAPTCHA_SITE_KEY:
    process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY ?? getSetting('NEXT_PUBLIC_HCAPTCHA_SITE_KEY'),

  // Dev and staging access restriction
  NEXT_PUBLIC_BASIC_AUTH_PASSWORD:
    process.env.NEXT_PUBLIC_BASIC_AUTH_PASSWORD ?? getSetting('NEXT_PUBLIC_BASIC_AUTH_PASSWORD'),
};

export default LOCAL;
