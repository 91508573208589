import DEV from './dev';
import LOCAL from './local';
import PROD from './prod';
import STAGING from './staging';
import { ConfigEnvironment, ConfigSet, ConfigValue } from './type';

interface Environment {
  debugTranslations: boolean;
  environment: 'Local' | 'Dev' | 'Staging' | 'Prod';
  isLocal: boolean;
  isProduction: boolean;
  isBasicAuthRequired: boolean;
}

interface EnvironmentList {
  [environment: string]: Environment;
}

const environments: EnvironmentList = {
  Local: {
    debugTranslations: true,
    environment: 'Local',
    isLocal: true,
    isProduction: false,
    isBasicAuthRequired: false,
  },
  Dev: {
    debugTranslations: false,
    environment: 'Dev',
    isLocal: false,
    isProduction: true,
    isBasicAuthRequired: true,
  },
  Staging: {
    debugTranslations: false,
    environment: 'Staging',
    isLocal: false,
    isProduction: true,
    isBasicAuthRequired: true,
  },
  Prod: {
    debugTranslations: false,
    environment: 'Prod',
    isLocal: false,
    isProduction: true,
    isBasicAuthRequired: false,
  },
};

const environmentCurrent = environments[process.env.NEXT_PUBLIC_ENVIRONMENT || 'Local']!;

export class AppConfig {
  env: ConfigEnvironment;

  constructor(env: ConfigEnvironment) {
    this.env = env;
  }

  fetchSetting(envVar: keyof ConfigEnvironment): string | undefined;
  fetchSetting(envVar: keyof ConfigEnvironment, isRequired: boolean): string | undefined;
  fetchSetting(envVar: keyof ConfigEnvironment, isRequired: true): string;
  fetchSetting(envVar: keyof ConfigEnvironment, isRequired = false): ConfigValue {
    const setting = this.env[envVar];
    if (isRequired && !setting) {
      throw new Error(`Missing ${envVar} in ${environmentCurrent.environment}`);
    }

    return setting;
  }

  get appConfig(): ConfigSet & Environment {
    return {
      mobileChannel: this.fetchSetting('CHANNEL') || '',

      apiDefaultLimit: Number.MAX_SAFE_INTEGER,
      apiTimeout: 60000,
      apiTokenDuration: 2 * 60 * 1000,
      apiRefreshTokenKey: '__REFRESH_TOKEN_KEY__',
      authUserKey: '__AUTH_USER__',
      apiTokenKey: '__TOKEN_KEY__',
      apiTokenUpdatedKey: '__TOKEN_UPDATED_KEY__',

      appName: this.fetchSetting('NEXT_PUBLIC_NAME') || 'SkillHero',
      apiBaseUrl: this.fetchSetting('NEXT_PUBLIC_API_URL', true),
      sentryDsn: this.fetchSetting('NEXT_PUBLIC_SENTRY_DSN') || '',

      publicWebBaseUrl:
        this.fetchSetting('NEXT_PUBLIC_WEB_BASE_URL') || 'https://app.skillhero.works',
      publicLandingBaseUrl:
        this.fetchSetting('NEXT_PUBLIC_LANDING_BASE_URL') || 'https://www.skillhero.works/',

      defaultNearbyLocationRadius: 100,

      // Feature flags
      passwordlessAuth:
        this.fetchSetting('NEXT_PUBLIC_SKILLHERO_PASSWORDLESS_AUTH_FLAG') === '1' || false,

      // Contentful Credentials
      contentfulSpaceId: this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_SPACE_ID') || '',
      contentfulEnvironmentId: this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID') || '',
      contentfulContentDeliveryApiKey:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_API_KEY') || '',
      contentfulContentManagementApiKey:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_CONTENT_MANAGEMENT_API_KEY') || '',
      contentfulContentPreviewApiKey:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_CONTENT_PREVIEW_API_KEY') || '',

      // Contentful Landing Page Request Parameters
      contentfulLandingContentType:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_LANDING_CONTENT_TYPE') || '',
      contentfulLandingFieldName:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_LANDING_FIELD_NAME') || '',
      contentfulLandingTargetEntryId:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_LANDING_TARGET_ENTRY_ID') || '',

      // Contentful Public Landing Page Request Parameters
      contentfulPublicLandingContentType:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_CONTENT_TYPE') || '',
      contentfulPublicLandingFieldName:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_FIELD_NAME') || '',
      contentfulPublicLandingTargetEntryId:
        this.fetchSetting('NEXT_PUBLIC_CONTENTFUL_PUBLIC_LANDING_TARGET_ENTRY_ID') || '',

      // Contentful Embeds via Embedly Credentials
      embedlyApiKey: this.fetchSetting('NEXT_PUBLIC_EMBEDLY_API_KEY') || '',

      // Auth0
      auth0Domain: this.fetchSetting('NEXT_PUBLIC_AUTH0_DOMAIN') || '',
      auth0ClientId: this.fetchSetting('NEXT_PUBLIC_AUTH0_CLIENT_ID') || '',
      auth0Audience: this.fetchSetting('NEXT_PUBLIC_AUTH0_AUDIENCE') || '',
      auth0RedirectUri: this.fetchSetting('NEXT_PUBLIC_AUTH0_REDIRECT_URI') || '',

      // Chameleon
      chameleonAppId: this.fetchSetting('NEXT_PUBLIC_CHAMELEON_APP_ID') || '',

      // Firebase
      firebaseApiKey: this.fetchSetting('NEXT_PUBLIC_FIREBASE_API_KEY') || '',
      firebaseAuthDomain: this.fetchSetting('NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN') || '',
      firebaseProjectId: this.fetchSetting('NEXT_PUBLIC_FIREBASE_PROJECT_ID') || '',
      firebaseStorageBucket: this.fetchSetting('NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET') || '',
      firebaseMessagingSenderId:
        this.fetchSetting('NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID') || '',
      firebaseAppId: this.fetchSetting('NEXT_PUBLIC_FIREBASE_APP_ID') || '',
      firebaseMeasurementId: this.fetchSetting('NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID') || '',
      firebaseVapidPublicKey: this.fetchSetting('NEXT_PUBLIC_FIREBASE_VAPID_PUBLIC_KEY') || '',

      // Algolia Search Credentials
      algoliaAppId: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_APP_ID') || '',
      algoliaSearchApiKey: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY') || '',
      algoliaIndexName: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_INDEX_NAME') || '',
      algoliaProfileIndexName: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_PROFILE_INDEX_NAME') || '',
      algoliaOrganizationIndexName:
        this.fetchSetting('NEXT_PUBLIC_ALGOLIA_ORGANIZATION_INDEX_NAME') || '',
      algoliaPostIndexName: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_POST_INDEX_NAME') || '',
      algoliaTopicIndexName: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_TOPIC_INDEX_NAME') || '',
      algoliaOpportunityIndexName:
        this.fetchSetting('NEXT_PUBLIC_ALGOLIA_OPPORTUNITY_INDEX_NAME') || '',
      algoliaSkillIndexName: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_SKILL_INDEX_NAME') || '',
      algoliaBlogPostIndexName: this.fetchSetting('NEXT_PUBLIC_ALGOLIA_BLOG_POST_INDEX_NAME') || '',
      algoliaInternalOrganizationsIndexName:
        this.fetchSetting('NEXT_PUBLIC_ALGOLIA_INTERNAL_ORGANIZATIONS_INDEX_NAME') || '',

      // Google Tag Manager
      gtmId: this.fetchSetting('NEXT_PUBLIC_GTM_ID') || '',

      // HCaptcha
      hcaptchaSiteKey: this.fetchSetting('NEXT_PUBLIC_HCAPTCHA_SITE_KEY') || '',

      // Dev and staging access restriction
      basicAuthPassword: this.fetchSetting('NEXT_PUBLIC_BASIC_AUTH_PASSWORD') || '',

      ...environmentCurrent,
    };
  }
}

const envs = {
  Local: LOCAL,
  Dev: DEV,
  Staging: STAGING,
  Prod: PROD,
};

const appConfig = new AppConfig(envs[environmentCurrent.environment]);
export default appConfig.appConfig;
