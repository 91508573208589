import * as base from '../base';

export type APIBackground = {
  id: number;
  image: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export class BackgroundsAPI extends base.ReadOnlyAPIBase<APIBackground> {
  baseUrl = 'backgrounds/';
}
