import * as base from '../base';
import { APILinkPreview, APIList, APISearchParams, Options } from '../types';

export type APIComment = {
  id: number;
  type: 'Post' | 'Comment';
  post?: number | null;
  comment?: number | null;
  text: string;
  likes: number[];
  identity: number;
  created: string;
  updated: string;
  createdBy: string;
  updatedBy: string;
  likeCount: number;
  linkPreview: APILinkPreview | null;
  latestReply: APIComment | null;
  replyCount: number;
  replyPost?: number;
  hashtags: string[];
};

export type APICommentInput = Partial<
  Omit<APIComment, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy' | 'likes' | 'likeCount'>
>;

export class CommentsAPI extends base.CrudAPIBase<APIComment, APICommentInput> {
  baseUrl = 'comments/';

  fetchPostComments(
    page?: number,
    limit?: number,
    searchParams?: APISearchParams,
    options?: Options
  ) {
    return this._list<APIList<APIComment>>(`${this.baseUrl}`, page, limit, searchParams, options);
  }

  fetchHighlightedComment(id: number) {
    return this._get<APIList<APIComment>>(`${this.baseUrl}${id}/highlight/`);
  }

  fetchCommentReplies(id: number) {
    return this._get<APIList<APIComment>>(`${this.baseUrl}${id}/replies/`);
  }

  like(id: number) {
    return this.postEmpty(`${this.baseUrl}${id}/like/`);
  }

  unlike(id: number) {
    return this.destroyEmpty(`${this.baseUrl}${id}/like/`);
  }
}
