import * as base from '../base';

export type APICategory = {
  id: number;
  name: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export type APICategoryInput = Partial<
  Omit<APICategory, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export class CategoriesAPI extends base.CrudAPIBase<APICategory, APICategoryInput> {
  baseUrl = 'categories/';
}
