import * as base from '../base';

export const PostReportReasons = [
  'spam',
  'violence',
  'illegal',
  'harrasment',
  'hate',
  'ip',
  'other',
] as const;

export type APIPostReportReasons = (typeof PostReportReasons)[number];

export type APIPostReport = {
  id: number;
  post: number;
  reason: APIPostReportReasons;
  details: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export type APIPostReportInput = Partial<
  Omit<APIPostReport, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export class PostReportsAPI extends base.CrudAPIBase<APIPostReport, APIPostReportInput> {
  baseUrl = 'post-reports/';
}
