import React, { createContext, useCallback } from 'react';

type SignupContext = {
  setRandomized: (page: number) => Promise<void>;
  deleteRandomized: () => Promise<void>;
  setChannel: (channel: string) => Promise<void>;
  deleteChannel: () => Promise<void>;
};

export const SignupContext = createContext<SignupContext>({
  setRandomized: async (page: number) => {},
  deleteRandomized: async () => {},
  setChannel: async (channel: string) => {},
  deleteChannel: async () => {},
});

export type SignupProviderProps = React.PropsWithChildren<{
  _setRandomized?: (page: number) => Promise<void>;
  _deleteRandomized?: () => Promise<void>;
  _setChannel?: (channel: string) => Promise<void>;
  _deleteChannel?: () => Promise<void>;
}>;

const SignupProvider: React.FC<SignupProviderProps> = ({
  children,
  _setRandomized,
  _deleteRandomized,
  _setChannel,
  _deleteChannel,
}) => {
  const setRandomized = useCallback(
    async (page: number) => {
      _setRandomized?.(page);
    },
    [_setRandomized]
  );

  const setChannel = useCallback(
    async (channel: string) => {
      _setChannel?.(channel);
    },
    [_setChannel]
  );

  const deleteRandomized = useCallback(async () => _deleteRandomized?.(), [_deleteRandomized]);
  const deleteChannel = useCallback(async () => _deleteChannel?.(), [_deleteChannel]);

  return (
    <SignupContext.Provider
      value={{
        setRandomized,
        deleteRandomized,
        setChannel,
        deleteChannel,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};

export default SignupProvider;
