import { Options } from 'ky';
import * as base from '../base';
import { APIImageSizes, APIList, APISearchParams } from '../types';
import { APILocation } from './locations';

export type APILocaleChoices = 'en' | 'pt';

export type APISubscriptionTypeChoices = 'free' | 'starter' | 'pro' | 'enterprise';

export type APISubscriptionCycleChoices = 'monthly' | 'annual';

export interface APIUser {
  id: number;
  email: string;
  avatar?: string;
  avatarSizes: APIImageSizes;
  geolocation: string | null;
  geolocationData: APILocation | null;
  isElevated: boolean;
  authMethod: string;
  uidHash: string;
  activeIdentity: number | null;
  firstName?: string;
  lastName?: string;
  fullName: string;
  mobileNumber?: string;
  isMobileAuthenticated?: boolean;
  state?: string;
  username: string;
  internalRole?: 'internal_admin' | 'internal_user';
  timezone?: string;
}

export interface APIMinimalUser {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  avatarSizes?: APIImageSizes;
  email: string;
}

export interface APIMinimalConnectionUser {
  avatar?: string;
  id: number;
  firstName?: string;
  lastName?: string;
  username: string;
  fullName: string;
  geolocationData: APILocation | null;
  identity: number;
}

export interface APIReadOnlyBlockedUser {
  fullName?: string;
  username?: string;
  avatarSizes?: APIImageSizes;
}

export type APIUserInput = Partial<Omit<APIUser, 'id' | 'avatarSizes' | 'geolocationData'>> & {
  password?: string;
};

export interface APIUserRegisterWalletResponse {
  editionsClaimed: number;
}

export class UsersAPI extends base.ReadOnlyAPIBase<APIUser> {
  baseUrl = 'users/';

  /**
   * @deprecated Use `api.identities.list` instead.
   */
  list(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return super.list(page, limit, searchParams, options);
  }

  fetchMe() {
    return super._get<APIUser>('users/me/');
  }

  updateMe(data: FormData) {
    return super._patch<APIUser>('users/me/', { body: data });
  }

  validateMe(data: FormData) {
    return super._patch<APIUser>('users/validate-me/', { body: data });
  }

  messageUsers(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIMinimalConnectionUser>>(
      `${this.baseUrl}message-users/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  validateDestroy() {
    return super._get<{ status: 'valid' | 'invalid' }>('users/validate-destroy/');
  }

  destroy(id: number | string) {
    return super.destroyEmpty(`${this.baseUrl}${id}/`);
  }
}
