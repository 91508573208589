import { createContext, useState } from 'react';

type ImageInfo = {
  url: string;
  width: number;
  height: number;
};

type Images = {
  image1?: ImageInfo;
  image2?: ImageInfo;
};

type SchoolsLandingContext = {
  images: Images;
  setImages: (value: Images) => void;
};

export const SchoolsLandingContext = createContext<SchoolsLandingContext>({
  images: {},
  setImages: () => {},
});

type SchoolsLandingProviderProps = React.PropsWithChildren<{}>;

const SchoolsLandingProvider: React.FC<SchoolsLandingProviderProps> = ({ children }) => {
  const [images, setImages] = useState<Images>({});

  return (
    <SchoolsLandingContext.Provider
      value={{
        images,
        setImages,
      }}
    >
      {children}
    </SchoolsLandingContext.Provider>
  );
};

export default SchoolsLandingProvider;
