import * as base from '../base';
import { APIBulkUpdateResult, APILinkPreview } from '../types';
import { APIUser } from './users';

export type APITopic = {
  id: number;
  name: string;
  description: string;
  category: number;
  categoryName: string;
  subcategory?: number | null;
  industry?: number | null;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  isFollowing: boolean;
  identity: number;
  creatorDetails: {
    name: string;
    username: string;
    avatar: string;
    type: 'member' | 'organization';
  };
  commentCount: number;
  postCount: number;
  followerCount: number;
  media: APITopicMedia[];
  linkPreview: APILinkPreview | null;
};

export type APITopicMedia = {
  id: number;
  topic: number;
  image: string;
  video: string;
  createdBy: APIUser;
  updatedBy: number;
  created: string;
  updated: string;
};

export type APITopicInput = Partial<
  Omit<
    APITopic,
    | 'id'
    | 'admin'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
    | 'isFollowing'
    | 'identity'
    | 'creatorDetails'
    | 'commentCount'
    | 'postCount'
    | 'followerCount'
    | 'media'
    | 'linkPreview'
  >
>;

export type APITopicMediaInput = Partial<
  Omit<APITopicMedia, 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export class TopicsAPI extends base.CrudAPIBase<APITopic, APITopicInput> {
  baseUrl = 'topics/';

  follow(id: number) {
    return this.postEmpty(`${this.baseUrl}${id}/follow/`);
  }

  unfollow(id: number) {
    return this.destroyEmpty(`${this.baseUrl}${id}/follow/`);
  }
}

export class TopicMediaAPI extends base.CrudAPIBase<APITopicMedia, APITopicMediaInput> {
  baseUrl = 'topic-media/';

  bulkUpdate(data: { instances: APITopicMediaInput[] }) {
    return this._patch<APIBulkUpdateResult<APITopicMedia>>(`${this.baseUrl}bulk-update/`, {
      json: data,
    });
  }
}
