import * as base from '../base';

type APIOrganizationJoinRequest = {
  id: number;
  organization: number;
  status: 'pending' | 'denied' | 'approved';
};

type APIOrganizationJoinRequestInput = {
  organization: number;
};

export class OrganizationJoinAPI extends base.CrudAPIBase<APIOrganizationJoinRequestInput> {
  baseUrl = 'organizations/join/';

  approve(id: number) {
    return super._post<APIOrganizationJoinRequest>(`${this.baseUrl}approve/${id}/`);
  }

  deny(id: number) {
    return super._post<APIOrganizationJoinRequest>(`${this.baseUrl}deny/${id}/`);
  }
}
