import * as base from '../base';
import { APIBulkUpdateResult, APILinkPreview } from '../types';
import { APIComment } from './comments';
import { APIUser } from './users';

export type APIRepostData = Omit<
  APIPost,
  | 'likes'
  | 'favorites'
  | 'hashtags'
  | 'likeCount'
  | 'latestComment'
  | 'favoriteCount'
  | 'commentCount'
  | 'topicPostCount'
  | 'isLiked'
  | 'isFavorited'
  | 'repostCount'
  | 'isReposted'
  | 'repostData'
>;

export type APIPost = {
  id: number;
  authorType: 'topic' | 'organization' | 'member';
  memberProfile?: string | null;
  organizationProfile?: string | null;
  opportunity?: number | null;
  topic?: number | null;
  postText: string;
  postUrl?: string | null;
  likes: number[];
  favorites: number[];
  hashtags: string[];
  identity: number;
  created: string;
  updated: string;
  createdBy: string;
  updatedBy: string;
  isPublic: boolean;
  likeCount: number;
  latestComment: APIComment | null;
  favoriteCount: number;
  commentCount: number;
  topicPostCount: number;
  isLiked: boolean;
  isFavorited: boolean;
  media: APIPostMedia[];
  linkPreview: APILinkPreview | null;
  repost: number;
  repostCount: number;
  isReposted: boolean;
  repostData: APIRepostData | 'Deleted' | null;
};

export type APILike = {
  id: number;
  post: number | null;
  comment: number | null;
  type: 'Post' | 'Comment';
  identity: number;
  createdBy: APIUser;
  updatedBy: number;
  created: string;
  updated: string;
};

export type APIFavorite = {
  id: number;
  post: number | null;
  identity: number;
  createdBy: APIUser;
  updatedBy: number;
  created: string;
  updated: string;
};

export type APIFeedInput = {
  offset: number;
  timestampStart?: string;
  type?: 'posts' | 'likes' | 'favorites' | 'topics' | 'allTopics';
  hashtag?: string;
  id__in?: string;
  topic?: number;
};

export type APIFeedPost =
  | {
      feedType: 'posts';
      data: APIPost;
      context: null;
    }
  | {
      feedType: 'likes';
      data: APIPost;
      context: APILike;
    }
  | {
      feedType: 'favorites';
      data: APIPost;
      context: APIFavorite;
    };

export type APIFeedData = {
  offset: number;
  count: number;
  timestampStart: string;
  posts: APIFeedPost[];
};

export type APIHashtagStatData = {
  numPosts: number;
  numLikes: number;
  numComments: number;
};

export type APIPostMedia = {
  id: number;
  post: number;
  image: string;
  video: string;
  createdBy: APIUser;
  updatedBy: number;
  created: string;
  updated: string;
};

export type APIPostInput = Partial<
  Omit<
    APIPost,
    | 'id'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
    | 'likes'
    | 'likeCount'
    | 'favoriteCount'
    | 'commentCount'
    | 'isLiked'
    | 'isFavorited'
  >
>;

export type APIPostMediaInput = Partial<
  Omit<APIPostMedia, 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export type APITopicList = {
  count: number;
  results: APIPost[];
};

export class PostsAPI extends base.CrudAPIBase<APIPost, APIPostInput> {
  baseUrl = 'posts/';

  like(id: number) {
    return this.postEmpty(`${this.baseUrl}${id}/like/`);
  }

  unlike(id: number) {
    return this.destroyEmpty(`${this.baseUrl}${id}/like/`);
  }

  favorite(id: number) {
    return this.postEmpty(`${this.baseUrl}${id}/favorite/`);
  }

  unfavorite(id: number) {
    return this.destroyEmpty(`${this.baseUrl}${id}/favorite/`);
  }

  feed(data: APIFeedInput) {
    const params = new URLSearchParams();
    params.append('offset', data.offset.toString());
    if (data.timestampStart) {
      params.append('timestampStart', data.timestampStart);
    }
    if (data.type) {
      params.append('type', data.type);
    }
    if (data.hashtag) {
      params.append('hashtag', data.hashtag);
    }
    if (data.id__in) {
      params.append('id__in', data.id__in);
    }
    if (data.topic) {
      params.append('topic', `${data.topic}`);
    }
    return this._get<APIFeedData>(`${this.baseUrl}feed/?${params.toString()}`);
  }

  createPost(data: FormData) {
    return this._post<APIPost>(`${this.baseUrl}`, { body: data });
  }

  fetchHashtagStats(data: string) {
    const params = new URLSearchParams();
    params.append('hashtag', data);
    return this._get<APIHashtagStatData>(`${this.baseUrl}hashtag-stats/?${params.toString()}`);
  }
}

export class PostMediaAPI extends base.CrudAPIBase<APIPostMedia, APIPostMediaInput> {
  baseUrl = 'post-media/';

  bulkUpdate(data: { instances: APIPostMediaInput[] }) {
    return this._patch<APIBulkUpdateResult<APIPostMedia>>(`${this.baseUrl}bulk-update/`, {
      json: data,
    });
  }
}
