import * as base from '../base';
import { APIList, APISearchParams, Options } from '../types';
import { APIOpportunity } from './opportunities';
import { APIProfile } from './profiles';

export const ApplicationStatuses = [
  'All',
  'Applied',
  'Reviewed',
  'Interviewed',
  'Hired',
  'Rejected',
  'Applied_Closed',
] as const;

export type APIOpportunityApplicationStatus = Exclude<(typeof ApplicationStatuses)[number], 'All'>;

export type APIOpportunityApplication = {
  id: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  opportunity: APIOpportunity;
  status: APIOpportunityApplicationStatus;
  email: string;
  mobileNumber: string;
  resume: string | null;
  notes: string;
  profile: APIProfile;
} & APIOpportunityApplicationRejectionMessage;

export type APIOpportunityApplicationInput = Partial<
  | Omit<
      APIOpportunityApplication,
      | 'id'
      | 'created'
      | 'updated'
      | 'createdBy'
      | 'updatedBy'
      | 'opportunity'
      | 'organization'
      | 'email'
      | 'mobileNumber'
      | 'resume'
      | 'profile'
      | 'internalCompany'
    >
  | APIOpportunityApplicationSubmissionInput
>;

export type APIOpportunityApplicationSubmissionInput = {
  opportunity: number;
  organization: number | null;
  internalOrganization: number | null;
  profile: number;
  email: string;
  mobileNumber: string;
  resume: string | null;
};

export type APIApplicationsData = {
  offset: number;
  count: number;
  timestampStart: string;
  results: APIOpportunityApplication[];
};

export type APIOpportunityApplicationsStatusKey =
  | 'applied'
  | 'reviewed'
  | 'interviewed'
  | 'hired'
  | 'rejected'
  | 'applied_closed';
export type APIOpportunityApplicationsCount = Record<
  APIOpportunityApplicationsStatusKey | 'all',
  number
>;
export type APICheckOpportunityApplication = {
  hasApplication: boolean;
};

export type APIOpportunityApplicationRejectionMessage = {
  rejectionSubject: string;
  rejectionBodyHtml: string; // Used for email
  rejectionBodyMarkdown?: string; // Used for chat message
  rejectionDelay?: number;
  rejectionDelayCountdown?: number;
  rejectionIsCancelled?: boolean;
};

export class OpportunityApplicationAPI extends base.CrudAPIBase<
  APIOpportunityApplication,
  APIOpportunityApplicationInput
> {
  baseUrl = 'opportunities/applications/';

  fetchCounts(query: APISearchParams) {
    const params = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      params.append(key, `${query[key]}`);
    });
    return this._get<APIOpportunityApplicationsCount>(`${this.baseUrl}count/?${params.toString()}`);
  }

  resume(id: string | number, options: any = {}) {
    return this._getBlob<Blob>(`${this.baseUrl}${id}/resume/`, {
      headers: options,
    });
  }

  fetchInternal(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIOpportunityApplication>>(
      `${this.baseUrl}internal/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  updateInternal(id: string | number, data: APIOpportunityApplicationInput) {
    return this._patch<APIOpportunityApplication>(`${this.baseUrl}${id}/internal/`, {
      json: data,
    });
  }

  fetchCountsInternal(query: APISearchParams) {
    const params = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      params.append(key, `${query[key]}`);
    });
    return this._get<APIOpportunityApplicationsCount>(
      `${this.baseUrl}internal/count?${params.toString()}`
    );
  }

  rejectAndNotify(id: string | number, data: APIOpportunityApplicationRejectionMessage) {
    return this._patch<APIOpportunityApplication>(
      `${this.baseUrl}${id}/reject_and_notify_applicant/`,
      {
        json: data,
      }
    );
  }

  cancelRejection(id: string | number) {
    return this._post<APIOpportunityApplication>(`${this.baseUrl}${id}/cancel-rejection/`);
  }
}
