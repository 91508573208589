import React, { createContext } from 'react';

type UTMContext = {
  setUTMCode: (data: string) => Promise<void>;
  getUTMCode: () => Promise<string | null | undefined>;
  deleteUTMCode: () => Promise<void>;
  setUTMOrg: (shouldUpdate: string) => Promise<void>;
  getUTMOrg: () => Promise<string | null | undefined>;
  deleteUTMOrg: () => Promise<void>;
};

export const UTMContext = createContext<UTMContext>({
  setUTMCode: async () => {},
  getUTMCode: async () => null,
  deleteUTMCode: async () => {},
  setUTMOrg: async () => {},
  getUTMOrg: async () => null,
  deleteUTMOrg: async () => {},
});

export type UTMProviderProps = React.PropsWithChildren<{
  setUTMCode: (data: string) => Promise<void>;
  getUTMCode: () => Promise<string | null | undefined>;
  deleteUTMCode: () => Promise<void>;
  setUTMOrg: (shouldUpdate: string) => Promise<void>;
  getUTMOrg: () => Promise<string | null | undefined>;
  deleteUTMOrg: () => Promise<void>;
}>;

const UTMProvider: React.FC<UTMProviderProps> = ({
  children,
  setUTMCode,
  getUTMCode,
  deleteUTMCode,
  setUTMOrg,
  getUTMOrg,
  deleteUTMOrg,
}) => {
  return (
    <UTMContext.Provider
      value={{
        setUTMCode,
        getUTMCode,
        deleteUTMCode,
        setUTMOrg,
        getUTMOrg,
        deleteUTMOrg,
      }}
    >
      {children}
    </UTMContext.Provider>
  );
};

export default UTMProvider;
