import * as base from '../base';
import { APIOrganizationUserInvite } from './organizationProfiles';

export type APIInviteCheckInput = {
  organization_id: number;
  email: string;
};

export type APIInviteCheck = {
  inviteExists: boolean;
};

export class OrganizationInviteAPI extends base.ReadOnlyAPIBase<APIOrganizationUserInvite> {
  baseUrl = 'organizations/invite/';

  detail(id: number) {
    return this._get<APIOrganizationUserInvite>(`${this.baseUrl}${id}/`);
  }

  check(data: APIInviteCheckInput) {
    return this._post<APIInviteCheck>(`${this.baseUrl}check/`, { json: data });
  }
}
