import { APIHashtagStats } from 'app/api/routes/hashtagStats';
import useApi from 'app/hooks/useApi';
import useProfile from 'app/hooks/useProfile';
import React, { createContext, useCallback, useEffect, useState } from 'react';

type HashtagStatsContext = {
  isLoading: boolean;
  stats: APIHashtagStats;
  isHashtagPage: boolean;
  setQuery: (query: string) => void;
  fetchHashtagStats: () => void;
  setIsHashtagPage: (value: boolean) => void;
  setStats: React.Dispatch<React.SetStateAction<APIHashtagStats>>;
};

const DEFAULT_STATS: APIHashtagStats = {
  posts: 0,
  comments: 0,
  likedComments: 0,
  likedPosts: 0,
};

export const HashtagStatsContext = createContext<HashtagStatsContext>({
  isLoading: false,
  stats: DEFAULT_STATS,
  isHashtagPage: false,
  setQuery: () => {},
  fetchHashtagStats: () => {},
  setIsHashtagPage: () => {},
  setStats: () => {},
});

const HashtagStatsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const api = useApi();
  const { identity } = useProfile();

  const [isHashtagPage, setIsHashtagPage] = useState(false);
  const [_query, _setQuery] = useState('');
  const [stats, setStats] = useState(DEFAULT_STATS);
  const [isLoading, setLoading] = useState(false);

  const fetchHashtagStats = useCallback(async () => {
    if (isLoading || !isHashtagPage || !identity) {
      return;
    }

    const [request] = api.hashtagStats.fetchStats(_query);
    try {
      const result = await request();
      setStats(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [_query, api.hashtagStats, identity, isHashtagPage, isLoading]);

  const setQuery = useCallback((value: string) => {
    _setQuery(value);
  }, []);

  useEffect(() => {
    if (_query && !!identity) {
      fetchHashtagStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_query, identity]);

  return (
    <HashtagStatsContext.Provider
      value={{
        stats,
        isHashtagPage,
        isLoading,
        setIsHashtagPage,
        fetchHashtagStats,
        setQuery,
        setStats,
      }}
    >
      {children}
    </HashtagStatsContext.Provider>
  );
};

export default HashtagStatsProvider;
