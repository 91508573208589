import { Theme } from 'app/constants/theme';
import * as base from '../base';

export interface APIChannel {
  key: string;
  description: string;
  themeData: Theme;
  isDefault: boolean;
  force: boolean;
}

export class ChannelsAPI extends base.ReadOnlyAPIBase<APIChannel> {
  baseUrl = 'channels/';

  fetchMe(forceChannel?: string) {
    return this._get<APIChannel>(
      forceChannel ? `${this.baseUrl}${forceChannel}/` : `${this.baseUrl}me/`,
      {
        public: true,
      }
    );
  }
}
