import appConfig from 'app/config/appConfig';
import { app as firebaseApp } from 'app/lib/firebase';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { getMessaging, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: `${appConfig.firebaseApiKey}`,
  authDomain: `${appConfig.firebaseAuthDomain}`,
  projectId: `${appConfig.firebaseProjectId}`,
  storageBucket: `${appConfig.firebaseStorageBucket}`,
  messagingSenderId: `${appConfig.firebaseMessagingSenderId}`,
  appId: `${appConfig.firebaseAppId}`,
  measurementId: `${appConfig.firebaseMeasurementId}`,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();

export const firebaseSignout = async () => {
  await signOut(auth);
};

export const checkSupported = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.warn('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getFirebaseUserId = async (token: string, identityId: number) => {
  if (auth.currentUser) {
    const tokenDetails = await auth.currentUser.getIdTokenResult();
    if (tokenDetails.claims.identity_id === `${identityId}`) {
      return auth.currentUser.uid;
    }
  }
  const userCredentials = await signInWithCustomToken(auth, token);
  const user = userCredentials.user;
  return user.uid;
};

export const getFirebaseOrgId = async (token: string) => {
  if (auth.currentUser) {
    const tokenDetails = await auth.currentUser.getIdTokenResult();
    return tokenDetails.claims.org_id ? `${tokenDetails.claims.org_id}` : '';
  }
  const userCredentials = await signInWithCustomToken(auth, token);
  const user = userCredentials.user;
  const tokenDetails = await user.getIdTokenResult();
  return tokenDetails.claims.org_id ? `${tokenDetails.claims.org_id}` : '';
};
