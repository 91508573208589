import ky, { HTTPError, Options as KyOptions } from 'ky-universal';
import omit from 'lodash/omit';
import appConfig from '../config/appConfig';
import { APIConfig } from './config';
import { APIList, APIRequest, APISearchParams, Options } from './types';

function buildSortString(sortColumn: string, sortDirection: 'asc' | 'desc'): string {
  return `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`;
}

type AccessToken = {
  accessToken: string;
  expiresIn: number;
  scope: string;
  tokenType: 'Bearer';
};

const _retryState = {
  retrying: false,
};

function isHttpError(error: Error): error is HTTPError {
  return error.name === 'HTTPError';
}

export class APIBase {
  protected readonly config: APIConfig;
  private ky: typeof ky;

  protected baseUrl: string = '';

  private token: string = '';
  private identity: number | null = null;

  constructor(options?: {
    token?: string;
    refreshToken?: string;
    identity?: number;
    setToken: (token: string) => Promise<void>;
    setRefreshToken: (token: string) => Promise<void>;
  }) {
    this.config = new APIConfig();

    const { token, refreshToken, identity, setToken, setRefreshToken } = options || {};

    if (token) {
      this.token = token;
    }

    if (identity) {
      this.identity = identity;
    }

    this.ky = ky.extend({
      hooks: {
        beforeRequest: [
          async (request: Request) => {
            if (appConfig.isLocal) {
              console.debug(
                '[Debug][ky]',
                this.config.baseUrl,
                request.method,
                request.url,
                request
              );
            }
          },
        ],
        beforeRetry: [
          async () => {
            if (_retryState.retrying) {
              return ky.stop;
            }
          },
          async () => {
            if (!refreshToken) {
              return ky.stop;
            }
          },
          async ({ request, error }) => {
            _retryState.retrying = true;

            // Retry non-HTTP errors, like network errors.
            if (!isHttpError(error)) {
              return;
            }

            // Retry errors that are not 401 errors, without refreshing the access token.
            if (error.response?.status !== 401) {
              _retryState.retrying = false;
              return;
            }

            try {
              const response = await ky.post('auth/refresh-token/', {
                prefixUrl: this.config.baseUrl,
                json: { token: refreshToken },
              });
              const { accessToken, tokenType } = await response.json<AccessToken>();
              this.token = accessToken;

              // Verify that the access token is valid.
              await ky.get('auth/ping/', {
                prefixUrl: this.config.baseUrl,
                headers: {
                  Authorization: `${tokenType} ${accessToken}`,
                },
              });

              await setToken?.(accessToken);

              request.headers.set('Authorization', `${tokenType} ${accessToken}`);
            } catch (e) {
              console.warn(
                `Failed to refresh the access token to retry ${request.method} ${request.url}`
              );
              console.warn({ e });

              // Log the user out and stop retrying.
              await setToken?.('');
              await setRefreshToken?.('');

              return ky.stop;
            } finally {
              _retryState.retrying = false;
            }
          },
        ],
      },
      retry: {
        limit: 5,
        methods: ['get', 'put', 'post', 'head', 'patch', 'delete', 'options', 'trace'],
        statusCodes: [401, 408, 413, 429, 502, 503, 504],
      },
    });
  }

  private getActualOptions(options: Options = {}): KyOptions {
    const actualOptions: KyOptions = omit(options, ['public']);

    actualOptions.headers = {
      ...actualOptions.headers,
      ...this.config.extraHeaders,
    };

    if (this.token) {
      actualOptions.headers = {
        ...actualOptions.headers,
        Authorization: `Bearer ${this.token}`,
      };
    }

    if (this.identity) {
      actualOptions.headers = {
        ...actualOptions.headers,
        Identity: this.identity.toString(),
      };
    }

    return {
      prefixUrl: this.config.baseUrl,
      timeout: this.config.timeout,
      ...actualOptions,
    };
  }

  // Base methods

  protected destroyEmpty(url: string, options?: Options): APIRequest<Response> {
    const controller = new AbortController();
    return [
      () => this.ky.delete(url, this.getActualOptions({ ...options, signal: controller.signal })),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected getEmpty(url: string, options?: Options): APIRequest<Response> {
    const controller = new AbortController();
    return [
      () => this.ky.get(url, this.getActualOptions({ ...options, signal: controller.signal })),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected head(url: string, options?: Options): APIRequest<Response> {
    const controller = new AbortController();
    return [
      () => this.ky.head(url, this.getActualOptions({ ...options, signal: controller.signal })),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected patchEmpty(url: string, options?: Options): APIRequest<Response> {
    const controller = new AbortController();
    return [
      () => this.ky.patch(url, this.getActualOptions({ ...options, signal: controller.signal })),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected postEmpty(url: string, options?: Options): APIRequest<Response> {
    const controller = new AbortController();
    return [
      () => this.ky.post(url, this.getActualOptions({ ...options, signal: controller.signal })),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected putEmpty(url: string, options?: Options): APIRequest<Response> {
    const controller = new AbortController();
    return [
      () => this.ky.put(url, this.getActualOptions({ ...options, signal: controller.signal })),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected _destroy<T>(url: string, options?: Options): APIRequest<T> {
    const [response, , , , controller] = this.destroyEmpty(url, options);
    return [
      () => response().then((response) => response.json()),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected _get<T>(url: string, options?: Options): APIRequest<T> {
    const [response, , , , controller] = this.getEmpty(url, options);
    return [
      () => response().then((response) => response.json()),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected _getBlob<T>(url: string, options?: Options): APIRequest<T | Blob> {
    const [response, , , , controller] = this.getEmpty(url, options);
    return [
      () => response().then((response) => response.blob()),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected _patch<T>(url: string, options?: Options): APIRequest<T> {
    const [response, , , , controller] = this.patchEmpty(url, options);
    return [
      () => response().then((response) => response.json()),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected _post<T>(url: string, options?: Options): APIRequest<T> {
    const [response, , , , controller] = this.postEmpty(url, options);
    return [
      () => response().then((response) => response.json()),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  protected _put<T>(url: string, options?: Options): APIRequest<T> {
    const [response, , , , controller] = this.putEmpty(url, options);
    return [
      () => response().then((response) => response.json()),
      url,
      this.token,
      options?.public || false,
      controller,
    ];
  }

  // Convenience methods

  protected _list<T, S extends APISearchParams = APISearchParams>(
    url: string,
    page = 1,
    limit?: number,
    searchParams?: S,
    options?: Options
  ): APIRequest<T> {
    const actualLimit = limit === -1 ? Number.MAX_SAFE_INTEGER : limit ?? this.config.defaultLimit;
    const offset = (page - 1) * actualLimit;

    let params: {
      [k: string]: string | string[] | number | number[] | boolean | undefined;
    } = {
      limit: actualLimit,
      offset,
      ...searchParams,
    };

    const sortColumn = searchParams?.sortColumn;
    const sortDirection = searchParams?.sortDirection;
    const order =
      sortColumn && sortDirection ? buildSortString(sortColumn, sortDirection) : undefined;

    try {
      delete params.sortColumn;
      delete params.sortDirection;
    } catch (e) {
      // pass
    }

    if (order) {
      params = { ...params, order };
    }

    const [request, , , , controller] = this._get<T>(url, {
      ...options,
      // Better than `any`...
      searchParams: params as { [k: string]: string | number | boolean },
    });

    return [
      request,
      `${url}?${new URLSearchParams(params as any).toString()}`,
      this.token,
      options?.public || false,
      controller,
    ];
  }
}

export class ReadOnlyAPIBase<T, S extends APISearchParams = APISearchParams> extends APIBase {
  extraOptions: {
    list?: Options;
    detail?: Options;
  } = {};

  list(page?: number, limit?: number, searchParams?: S, options?: Options) {
    return super._list<APIList<T>, S>(this.baseUrl, page, limit, searchParams, {
      ...options,
      ...this.extraOptions.list,
    });
  }

  detail(id: number | string, options?: Options) {
    return super._get<T>(`${this.baseUrl}${id}/`, {
      ...options,
      ...this.extraOptions.detail,
    });
  }
}

export class CrudAPIBase<
  T,
  I = Partial<T>,
  S extends APISearchParams = APISearchParams,
> extends ReadOnlyAPIBase<T, S> {
  extraOptions: typeof ReadOnlyAPIBase.prototype.extraOptions & {
    create?: Options;
    update?: Options;
    destroy?: Options;
  } = {};

  create(data: I) {
    return super._post<T>(this.baseUrl, {
      json: data,
      ...this.extraOptions.create,
    });
  }

  update(id: number | string, data: I) {
    return super._patch<T>(`${this.baseUrl}${id}/`, {
      json: data,
      ...this.extraOptions.update,
    });
  }

  destroy(id: number | string) {
    return super.destroyEmpty(`${this.baseUrl}${id}/`, this.extraOptions.destroy);
  }
}
