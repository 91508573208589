import * as base from '../base';

export type APIUserDevice = {
  type: 'ios' | 'android' | 'web';
  token: string;
};

export class UserDevicesAPI extends base.CrudAPIBase<APIUserDevice> {
  baseUrl = 'user-devices/';

  register(data: APIUserDevice) {
    return this.postEmpty(`${this.baseUrl}register/`, { json: data });
  }

  unregister(data: APIUserDevice) {
    return this.postEmpty(`${this.baseUrl}unregister/`, { json: data });
  }
}
