import * as base from '../base';
import { APIList, APISearchParams, Options } from '../types';
import { APIIndustry } from './industries';
import { APILocation } from './locations';
import { APITrade } from './trades';

export type APIMinimalOpportunitySkill = {
  id: number;
  name: string;
  skill: number;
};

export type APIMinimalOpportunityCertification = {
  id: number;
  name: string;
  certification: number;
};

export type APIOpportunity = {
  id: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  applicantsCount?: number;
  type: 'Job' | 'Training & Education';
  organization?: string;
  organizationName?: string | null;
  organizationImage?: string | null;
  minAge: null | 14 | 15 | 16 | 17 | 18 | 19;
  isEntryLevel?: boolean | null;
  isFavorited?: boolean | null;
  jobType: 'Apprenticeship' | 'Full-Time' | 'Part-Time' | 'Contract' | '';
  trainingType: 'Degree' | 'Continuing Education' | 'Training' | 'Apprenticeship' | '';
  status: 'Draft' | 'Published' | 'Closed' | 'Deleted';
  administrator?: number;
  datePublished?: string | null;
  title: string;
  location: string;
  geolocation: string;
  geolocationData: APILocation;
  description: string;
  education: 'None' | 'High School Diploma' | "Associate's Degree" | "Bachelor's Degree";
  compensationMin: number | null;
  compensationMax: number | null;
  compensationType: 'Annual Salary' | 'Hourly Rate' | '';
  source: string;
  sourceType: string;
  originalListing?: string;
  industries: number[];
  trades: number[];
  skills: number[]; // associated interal skills
  certifications: number[]; // associated interal certification
  opportunitySkills: APIMinimalOpportunitySkill[]; // all skills including freeform
  opportunityCertifications: APIMinimalOpportunityCertification[]; // all certifications including freeform
  industriesData: APIIndustry[];
  tradesData: APITrade[];
  viewCount?: number;
  latestBoost?: APIOpportunityBoost | null;
  cost: number | null;
  costType: 'Paid' | 'Free' | 'Sponsored' | '';
  hasApplication?: boolean;
  internalOrganization?: number;
};

export type APIOpportunityInput = Partial<
  Omit<
    APIOpportunity,
    | 'id'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
    | 'applicantsCount'
    | 'datePublished'
    | 'latestBoost'
  >
>;

export interface APIOpportunitySearchParams extends APISearchParams {
  status?: '' | APIOpportunity['status'];
  title?: string;
  location?: string;
  geolocation?: string;
  radius?: '' | number;
  industries?: string;
  state?: string;
  type?: '' | APIOpportunity['type'];
  min_age?: '' | 'none' | 16 | 18;
  is_entry_level?: '' | 'true' | 'false';
  job_type?: '' | APIOpportunity['jobType'];
  training_type?: '' | APIOpportunity['trainingType'];
  cost_type?: '' | 'Paid' | 'Free or Sponsored'; // This differs from APIOpportunity['costType']
  education?: '' | APIOpportunity['education'];
  trades?: '' | string | number;
  profile_default?: '' | 'true' | 'false';
  compensation_min?: string;
  compensation_max?: string;
  compensation_type?: '' | 'annual' | 'hourly';
}

type SiteMapParams = {
  internal?: boolean;
  slug?: string;
  [k: string]: string | boolean | undefined;
};

export type APIOpportunityCompensationHistogramData = {
  data: number[];
  min: number;
  max: number;
  step: number;
};

export type APIOpportunityCompensationHistogram = {
  annual: APIOpportunityCompensationHistogramData;
  hourly: APIOpportunityCompensationHistogramData;
};

export class OpportunitiesAPI extends base.CrudAPIBase<
  APIOpportunity,
  APIOpportunityInput,
  APIOpportunitySearchParams
> {
  baseUrl = 'opportunities/';
  extraOptions = {
    list: { public: true },
    detail: { public: true },
  };

  duplicate(id: number) {
    return this._post<APIOpportunity>(`${this.baseUrl}${id}/duplicate/`);
  }

  sitemap(query: SiteMapParams) {
    const params = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      params.append(key, `${query[key]}`);
    });
    return this._get<number[]>(`${this.baseUrl}sitemap/?${params.toString()}`);
  }

  similar(
    id: number,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams,
    options: Options = { public: true }
  ) {
    return this._list<APIList<APIOpportunity>>(
      `${this.baseUrl}${id}/similar/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  favorite(id: number) {
    return this.postEmpty(`${this.baseUrl}${id}/favorite/`);
  }

  unfavorite(id: number) {
    return this.destroyEmpty(`${this.baseUrl}${id}/favorite/`);
  }

  favorites(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIOpportunity>>(
      `${this.baseUrl}favorites/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  fetchCompensationHistogram(searchParams?: APISearchParams, options: Options = { public: true }) {
    return this._list<APIOpportunityCompensationHistogram>(
      `${this.baseUrl}compensation-histogram/`,
      undefined,
      undefined,
      searchParams,
      options
    );
  }
}

export type APIOpportunityBoost = {
  id: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  opportunity: number;
  isActive: boolean;
};

export type APIOpportunityBoostInput = Partial<
  Omit<APIOpportunityBoost, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export class OpportunityBoostAPI extends base.CrudAPIBase<
  APIOpportunityBoost,
  APIOpportunityBoostInput
> {
  baseUrl = 'opportunities/boosts/';
}
