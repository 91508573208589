import * as base from '../base';
import { APIList } from '../types';

export type APILocationType = 'place' | 'postcode' | 'address' | 'region' | 'country';

export interface APILocation {
  id: string;
  type: APILocationType;
  name: string;
  coordinates: string;
  state: string;
  country: string;
  locality?: string;
}

export class LocationsAPI extends base.ReadOnlyAPIBase<APILocation> {
  baseUrl = 'locations/';

  search(query: string) {
    return this._list<APIList<APILocation>>(
      `${this.baseUrl}search/`,
      undefined,
      undefined,
      {
        query,
      },
      { public: true }
    );
  }
}
