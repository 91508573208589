import * as base from '../base';

export type APISubcategory = {
  id: number;
  name: string;
  category: number;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export type APISubcategoryInput = Partial<
  Omit<APISubcategory, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy'>
>;

export class SubcategoriesAPI extends base.CrudAPIBase<APISubcategory, APISubcategoryInput> {
  baseUrl = 'subcategories/';
}
