import * as base from '../base';
import { APIOrganizationProfile } from './organizationProfiles';
import { APIProfileConnection } from './profiles';

export type APIFollower = {
  id: number | undefined;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  email: string | null;
  avatar: string | null;
  isFollowingEachOther: boolean;
  profile: APIProfileConnection | APIOrganizationProfile;
  key: string;
};

export class FollowersAPI extends base.CrudAPIBase<APIFollower> {
  baseUrl = 'followers/';
}
