import * as base from '../base';

export type APITrade = {
  id: number;
  name: string;
  slug: string;
  indefiniteArticle: string;
};

export class TradesAPI extends base.ReadOnlyAPIBase<APITrade> {
  baseUrl = 'attributes/trades/';
  extraOptions = {
    list: { public: true },
  };
}
