import * as base from '../base';
import { APIImageSizes } from '../types';
import { APIMinimalUser } from './users';

export type APIIdentity = {
  id: number;
  name: string;
  avatarSizes: APIImageSizes;
  organization: number | null;
  profile: number | null;
  role: 'organization_admin' | 'organization_owner' | 'organization_user' | 'organization_member';
  slug: string;
  type: 'organization' | 'member';
  isPublic: boolean;
  isFollowing: boolean;
  isVerified: boolean;
  hasPosts: boolean;
};

export type APIIdentityWithUser = APIIdentity & {
  user: APIMinimalUser;
};

export class IdentitiesAPI extends base.ReadOnlyAPIBase<APIIdentity> {
  baseUrl = 'identities/';

  fetchAvailable() {
    return this._get<APIIdentity[]>(`${this.baseUrl}available/`);
  }

  fetchMe() {
    return this._get<APIIdentity>(`${this.baseUrl}me/`);
  }
}
