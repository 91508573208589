import React, { createContext, useCallback, useState } from 'react';

type ImageInfo = {
  url: string;
  width: number;
  height: number;
};

type Images = {
  heroImage1?: ImageInfo;
  heroImage2?: ImageInfo;
  secondSectionImage?: ImageInfo;
};

type TradeWorldContext = {
  collapsed: boolean;
  images: Images;
  setImages: (value: Images) => void;
  handleCollapse: (collapsed: boolean) => Promise<void>;
};

export const TradeWorldContext = createContext<TradeWorldContext>({
  collapsed: true,
  images: {},
  setImages: () => {},
  handleCollapse: async () => {},
});

type TradeWorldProviderProps = React.PropsWithChildren<{
  setCookie: () => Promise<void>;
  deleteCookie: () => Promise<void>;
  getCookie: () => Promise<string | null | undefined>;
}>;

const TradeWorldProvider: React.FC<TradeWorldProviderProps> = ({
  children,
  setCookie,
  deleteCookie,
  getCookie,
}) => {
  const [images, setImages] = useState<Images>({});
  const [collapsed, setCollapsed] = useState(true);

  const handleCollapse = useCallback(
    async (_collapsed: boolean) => {
      if (_collapsed) {
        await setCookie();
      } else {
        await deleteCookie();
      }

      setCollapsed(_collapsed);
    },
    [deleteCookie, setCookie]
  );

  // useMount(() => {
  //   const checkCookie = async () => {
  //     const _collapsed = await getCookie();
  //     setCollapsed(!!_collapsed);
  //   };
  //   checkCookie();
  // });

  return (
    <TradeWorldContext.Provider
      value={{
        images,
        setImages,
        collapsed,
        handleCollapse,
      }}
    >
      {children}
    </TradeWorldContext.Provider>
  );
};

export default TradeWorldProvider;
