import * as base from '../base';

export type APITradePath = {
  id: number;
  name: string;
};

export class TradePathsAPI extends base.ReadOnlyAPIBase<APITradePath> {
  baseUrl = 'attributes/trade-paths/';
  extraOptions = {
    list: { public: true },
  };
}
