import * as base from '../base';

export type APIBlogSubscribeInput = {
  email: string;
  firstName: string;
  lastName: string;
  subscribed: boolean;
};

export type APIBlogSubscribeResult = {
  subscribed: boolean;
  message?: string;
};

export class BlogAPI extends base.APIBase {
  baseUrl = 'blog/';

  subscribe(data: APIBlogSubscribeInput) {
    return super._post<APIBlogSubscribeResult>(`${this.baseUrl}subscribe/`, {
      json: data,
    });
  }
}
