import * as base from '../base';

export type APIBadge = {
  id: number;
  name: string;
  description?: string;
  type: string;
  image: string;
  isActive: boolean;
  deleteDate: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export class BadgesAPI extends base.ReadOnlyAPIBase<APIBadge> {
  baseUrl = 'badges/';
}
