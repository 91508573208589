import API from 'app/api';
import React, { useMemo } from 'react';
import { useToken } from '../token';

type APIContext = API;

export const APIContext = React.createContext<APIContext>(new API());

export type APIProviderProps = React.PropsWithChildren<{}>;

export const APIProvider: React.FC<APIProviderProps> = ({ children }) => {
  const { token, refreshToken, identity, setToken, setRefreshToken } = useToken();

  const api = useMemo(() => {
    return new API({
      token,
      refreshToken,
      identity: identity ?? undefined,
      setToken,
      setRefreshToken,
    });
  }, [token, refreshToken, identity, setToken, setRefreshToken]);

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
};
