import * as base from '../base';
import { APIBulkUpdateResult } from '../types';
import { APILocation } from './locations';

export type APIOrganizationLocation = {
  id: number;
  organization: number;
  name: string;
  geolocation: string;
  geolocationData: APILocation;
  ordering: number;
  isActive: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
};

export type APIOrganizationLocationInput = Partial<
  Omit<
    APIOrganizationLocation,
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
    | 'isActive'
    | 'geolocation'
    | 'geolocationData'
  > & {
    geolocation: string | null;
    address: string;
  }
>;

export class OrganizationLocationsAPI extends base.CrudAPIBase<
  APIOrganizationLocation,
  APIOrganizationLocationInput
> {
  baseUrl = 'organizations/locations/';

  detail(id: number) {
    return this._get<APIOrganizationLocation>(`${this.baseUrl}${id}/`);
  }

  bulkUpdate(data: { instances: APIOrganizationLocationInput[] }) {
    return super._patch<APIBulkUpdateResult<APIOrganizationLocation>>(
      `${this.baseUrl}bulk-update/`,
      {
        json: data,
      }
    );
  }
}
