import { APIMinimalConnectionUser, APIUser } from './users';

import * as base from '../base';
import {
  APIBulkUpdateResult,
  APICertificationImageSizes,
  APIList,
  APISearchParams,
  Options,
} from '../types';
import { APIBackground } from './backgrounds';
import { APIBadge } from './badges';
import { APIInternalOrganization } from './internalOrganizations';
import { APILocation } from './locations';
import { APIOrganizationPrivateProfile, APIOrganizationProfile } from './organizationProfiles';
import { APIInternalSkillStamp } from './skillStamps';
import { APISkill } from './skills';

export interface APIProfileSearchParams extends APISearchParams {
  trade?: '' | string;
  location?: string;
  geolocation?: string;
  radius?: '' | number;
}

export interface APIProfileBadge {
  id: number;
  badge: APIBadge;
  profile: number;
  isActive: boolean;
  created: string;
  createdBy: number;
  updated: string;
  updatedBy: number;
}

export interface APIProfileCertification {
  id: number;
  profile: number;
  name: string;
  certification?: number | null;
  organization: string;
  issuer: string;
  startDateMonth: number | null;
  startDateYear: number | null;
  endDateMonth: number | null;
  endDateYear: number | null;
  description: string;
  image: string;
  imageSizes: APICertificationImageSizes;
  internalImage: string;
  url: string;
  isVerified: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  deletedAt: string;
}

export interface APIProfileEducation {
  id: number;
  profile: number;
  isCurrent: boolean;
  degreeCertification: string;
  schoolName: string;
  relevantClassworkA: string;
  relevantClassworkB: string;
  relevantClassworkC: string;
  startDateMonth: number | null;
  startDateYear: number | null;
  endDateMonth: number | null;
  endDateYear: number | null;
  description: string;
  isVerified: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  deletedAt: string;
  isFromHrFlow: boolean;
  hrFlowSchoolName: string;
  hrFlowDegreeCertification: string;
}

export interface APIProfileExperience {
  id: number;
  profile: number;
  isCurrent: boolean;
  positionTitle: string;
  employmentType: string;
  companyName: string;
  geolocation: string | null;
  geolocationData: APILocation | null;
  startDateMonth: number | null;
  startDateYear: number | null;
  endDateMonth: number | null;
  endDateYear: number | null;
  industry: string;
  description: string;
  isVerified: string;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  companyInfo: APIInternalOrganization | APIOrganizationPrivateProfile; // Private because of OrganizationProfileMinimalSerializer
  isFromHrFlow: boolean;
  hrFlowPositionTitle: string;
  hrFlowCompanyName: string;
}

export interface APIProfileExperienceOriginInput {
  profileExperience: number;
  originType?: 'Social Media' | 'Referral' | 'Recruiter' | 'Stagging Agency' | 'Other';
  socialMediaType?: 'Facebook' | 'Twitter' | 'Youtube' | 'Tiktok' | 'Other';
  isFromSkillhero: boolean;
  other?: string;
}

export interface APIProfileSkill {
  id: number;
  profile: number;
  type: string;
  name: string;
  skill?: APISkill;
  description: string;
  isVerified: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
  deletedAt: string;
}

export interface APIOfferPreference {
  id: number;
  trade: number;
  industry: number;
  geolocation: string | null;
  geolocationData: APILocation | null;
  radius: number;
  jobOpportunities: boolean;
  trainingOpportunities: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
}

export type APIProfile = {
  id: number;
  about: string;
  type: 'standard';
  user: APIUser;
  isMentor: boolean;
  isActive: boolean;
  isPublic: boolean;
  isVisibleMembers: boolean;
  isVisibleOrganizations: boolean;
  isVerified: boolean;
  isFeatured: boolean;
  headline: string;
  background: string;
  defaultBackground?: APIBackground;
  willRelocate: boolean;
  badges: APIProfileBadge[];
  profileCertification: APIProfileCertification[];
  profileEducation: APIProfileEducation[];
  profileExperience: APIProfileExperience[];
  profileSkills: APIProfileSkill[];
  latestExperience: APIProfileExperience | null;
  youtubeUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  tiktokUrl: string;
  created: string;
  updated: string;
  trade?: number;
  industry?: number;
  tradeName?: string;
  industryName?: string;
  createdBy: number;
  updatedBy: number;
  isFollowing: boolean;
  hasOrganization: boolean;
  offerPreference: APIOfferPreference | null;
  profileComplete: boolean;
  profileCompleteLocAndPic: boolean;
  profileCompleteEducation: boolean;
  profileCompleteSkill: boolean;
  profileCompleteCert: boolean;
  profileCompleteInvite: boolean;
  profileCompleteGiftClaimed: boolean;
  profileCompleteGiftOptedOut: boolean;
  profileSkillStamps: APIInternalSkillStamp[];
  isReady: boolean;
  hasTransportation: boolean;
  authorizedUs: boolean;
  militaryStatus: 'None' | 'Active' | 'Veteran' | 'Spouse';
  channel: '' | 'NCCER';
  signupRoleSelection?: string;
  hasApplications: boolean;
  resumeBuilderOptedOut: boolean;
  resumeBuilderResumeUploaded: boolean;
};

export type APIProfileConnection = {
  id: number;
  isFollowing: boolean;
  isMentor: boolean;
  isVerified: boolean;
  latestExperience: APIProfileExperience | null;
  user: APIMinimalConnectionUser;
};

export type APIProfileInput = Partial<
  Omit<
    APIProfile,
    | 'id'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
    | 'isActive'
    | 'latestExperience'
    | 'isFollowing'
    | 'defaultBackground'
  >
> & {
  channel?: 'NCCER' | '';
  utmCode?: string;
  defaultBackground?: number;
};

export type APIProfileEducationInput = Partial<
  Omit<APIProfileEducation, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy' | 'isActive'>
>;

export type APIProfileExperienceInput = Partial<
  Omit<
    APIProfileExperience,
    'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy' | 'isActive' | 'geolocationData'
  >
>;

export type APIProfileSkillInput = Partial<
  Omit<
    APIProfileSkill,
    'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy' | 'skill' | 'isActive'
  > & {
    skill: number;
  }
>;

export type APIProfileCertificationInput = Partial<
  Omit<
    APIProfileCertification,
    'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy' | 'isActive'
  >
>;

export type APIOfferPreferenceInput = Partial<
  Omit<APIOfferPreference, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy'> & {
    location: string;
  }
>;

export interface APIEmailPreference {
  id: number;
  profile: number;
  receiveNotificationEmails: boolean;
  receiveMarketingEmails: boolean;
  receiveApplicationEmails: boolean;
  receiveEndorsementEmails: boolean;
  created: string;
  updated: string;
  createdBy: number;
  updatedBy: number;
}

export type APIProfileEmailPreferenceCodeResponse = {
  id: number;
  emailPreference: APIEmailPreference | null;
  channel: string;
};

export type APIEmailPreferenceInput = Partial<
  Omit<APIEmailPreference, 'id' | 'created' | 'updated' | 'createdBy' | 'updatedBy' | 'profile'> & {
    emailPreferencesPageCode: string;
  }
>;

export interface APIProfileSearchParams extends APISearchParams {
  id__in?: string;
  is_mentor?: boolean;
  is_featured?: boolean;
  trade?: string;
  industry?: string;
}

export type APIProfileResumeBuilderInput = {
  resume: string | null;
};

export type APIProfileHrFlowEducation = {
  key: string;
  dateStart: string | null;
  dateEnd: string | null;
  title: string | null;
  description: string | null;
  school: string | null;
};

export type APIProfileHrFlowExperience = {
  key: string;
  dateStart: string | null;
  dateEnd: string | null;
  title: string | null;
  company: string | null;
  description: string | null;
  locationId: string | null;
  locationName: string | null;
};

export type APIProfileHrFlowDataResponse = {
  mobileNumber: string | null;
  locationId: string | null;
  locationName: string | null;
  educations: APIProfileHrFlowEducation[];
  experiences: APIProfileHrFlowExperience[];
  skills: APISkill[];
};

export class ProfilesAPI extends base.CrudAPIBase<
  APIProfile,
  APIProfileInput,
  APIProfileSearchParams
> {
  baseUrl = 'profiles/';
  extraOptions = {
    list: { public: true },
  };

  fetchMe() {
    return this._get<APIProfile>(`${this.baseUrl}me/`);
  }

  fetchPDFMe(options: any = {}) {
    return this._getBlob<Blob>(`${this.baseUrl}me/`, { headers: options });
  }

  fetchPDF(options: any = {}, username: string) {
    return this._getBlob<Blob>(`${this.baseUrl}${username}/resume/`, { headers: options });
  }

  createMe(data?: FormData) {
    if (data) {
      return this._put<APIProfile>(`${this.baseUrl}me/`, { body: data });
    }
    return this._put<APIProfile>(`${this.baseUrl}me/`);
  }

  patchMe(data: FormData) {
    return this._patch<APIProfile>(`${this.baseUrl}me/`, { body: data });
  }

  updateMe(data: FormData) {
    return this._put<APIProfile>(`${this.baseUrl}me/`, { body: data });
  }

  validateMe(data: FormData) {
    return this._put<APIProfile>(`${this.baseUrl}validate-me/`, { body: data });
  }

  detail(username: string) {
    return super._get<APIProfile>(`${this.baseUrl}${username}/`);
  }

  fetchProfileEducation(id: number) {
    return super._get<APIProfileEducation>(`${this.baseUrl}education/${id}/`);
  }

  createProfileEducation(data: { instances: APIProfileEducationInput[] }) {
    return super._post<APIBulkUpdateResult<APIProfileEducation>>(
      `${this.baseUrl}education/bulk-update/`,
      {
        json: data,
      }
    );
  }

  validateProfileEducation(data: { instances: APIProfileEducationInput[] }) {
    return super._post(`${this.baseUrl}education/bulk-validate/`, {
      json: data,
    });
  }

  updateProfileEducation(id: number, data: APIProfileEducationInput) {
    return super._patch<APIProfileEducation>(`${this.baseUrl}education/${id}/`, { json: data });
  }

  deleteProfileEducation(id: number) {
    return super.destroyEmpty(`${this.baseUrl}education/${id}/`);
  }

  fetchProfileExperience(id: number) {
    return super._get<APIProfileExperience>(`${this.baseUrl}experiences/${id}/`);
  }

  createProfileExperience(data: { instances: APIProfileExperienceInput[] }) {
    return super._post<APIBulkUpdateResult<APIProfileExperience>>(
      `${this.baseUrl}experiences/bulk-update/`,
      {
        json: data,
      }
    );
  }

  createProfileExperienceOrigin(data: APIProfileExperienceOriginInput) {
    return super._post(`${this.baseUrl}experience_origin/`, {
      json: data,
    });
  }

  validateProfileExperience(data: { instances: APIProfileExperienceInput[] }) {
    return super._post(`${this.baseUrl}experiences/bulk-validate/`, {
      json: data,
    });
  }

  updateProfileExperience(id: number, data: APIProfileExperienceInput) {
    return super._patch<APIProfileExperience>(`${this.baseUrl}experiences/${id}/`, {
      json: data,
    });
  }

  deleteProfileExperience(id: number) {
    return super.destroyEmpty(`${this.baseUrl}experiences/${id}/`);
  }

  fetchProfileSkill(id: number) {
    return super._get<APIProfileSkill>(`${this.baseUrl}skills/${id}/`);
  }

  createProfileSkill(data: { instances: APIProfileSkillInput[] }) {
    return super._post<APIBulkUpdateResult<APIProfileSkill>>(`${this.baseUrl}skills/bulk-update/`, {
      json: data,
    });
  }

  validateProfileSkill(data: { instances: APIProfileSkillInput[] }) {
    return super._post(`${this.baseUrl}skills/bulk-validate/`, {
      json: data,
    });
  }

  updateProfileSkill(id: number, data: APIProfileSkillInput) {
    return super._patch<APIProfileSkill>(`${this.baseUrl}skills/${id}/`, { json: data });
  }

  deleteProfileSkill(id: number) {
    return super.destroyEmpty(`${this.baseUrl}skills/${id}/`);
  }

  fetchProfileCertification(id: number) {
    return super._get<APIProfileCertification>(`${this.baseUrl}certifications/${id}/`);
  }

  createProfileCertification(data: { instances: APIProfileCertificationInput[] }) {
    return super._post<APIBulkUpdateResult<APIProfileCertification[]>>(
      `${this.baseUrl}certifications/bulk-update/`,
      {
        json: data,
      }
    );
  }

  updateProfileCertification(id: number, data: APIProfileCertificationInput) {
    return super._patch<APIProfileCertification>(`${this.baseUrl}certifications/${id}/`, {
      json: data,
    });
  }

  deleteProfileCertification(id: number) {
    return super.destroyEmpty(`${this.baseUrl}certifications/${id}/`);
  }

  createOfferPreference(data: APIOfferPreferenceInput) {
    return super._post<APIOfferPreferenceInput>(`${this.baseUrl}offer-preferences/`, {
      json: data,
    });
  }

  follow(username: string) {
    return this.postEmpty(`${this.baseUrl}${username}/follow/`);
  }

  unfollow(username: string) {
    return this.destroyEmpty(`${this.baseUrl}${username}/follow/`);
  }

  connections(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIProfileConnection>>(
      `${this.baseUrl}connections/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  suggestions(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIProfileConnection>>(
      `${this.baseUrl}suggested/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  organizations(
    username: string,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams,
    options?: Options
  ) {
    return this._list<APIList<APIOrganizationProfile>>(
      `${this.baseUrl}${username}/organizations/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  fetchEmailPreferenceByCode(data: string) {
    const params = new URLSearchParams();
    params.append('email_preferences_page_code', data);
    return super._get<APIProfileEmailPreferenceCodeResponse>(
      `${this.baseUrl}email-preferences/me/?${params.toString()}`
    );
  }

  fetchEmailPreference() {
    return super._get<APIProfileEmailPreferenceCodeResponse>(
      `${this.baseUrl}email-preferences/me/`
    );
  }

  createEmailPreference(data: APIEmailPreferenceInput) {
    return super._post<APIEmailPreferenceInput>(`${this.baseUrl}email-preferences/`, {
      json: data,
    });
  }

  resetEmailPreferences() {
    return this.destroyEmpty(`${this.baseUrl}email-preferences/me/`);
  }

  parseResume(data: APIProfileResumeBuilderInput) {
    return super._post<APIProfileHrFlowDataResponse>(`${this.baseUrl}parse-resume/`, {
      json: data,
    });
  }
}
